<template>
  <v-card>
    <!-- dialog select list -->
    <v-dialog
      v-model="dialogList"
      width="600px"
    >
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            <!-- {{ `${$t('select')} Contact List` }} -->
            {{ saveText }}
          </h3>
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-divider />

        <v-card-text>
          <v-row class="mt-2">
            <v-select
              v-model="selectedList"
              :items="listName"
              item-text="label"
              dense
              :label="`${saveText}`"
              outlined
              filled
            ></v-select>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#ffeeee"
            @click="cancel"
          >
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>

          <!-- <v-btn color="primary" class="" @click="openContactList">
             <v-icon>
               {{icons.mdiPlus}}
             </v-icon>
            {{ `List` }}
          </v-btn> -->
          <v-btn
            color="success"
            class=""
            @click="agree"
          >
            {{ saveText ? saveText : $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog label -->
    <dialogAddListContact ref="dialogAddListContact"></dialogAddListContact>
  </v-card>
</template>

<script>
import { uuid } from 'vue-uuid'
import { mdiLabel, mdiPlus } from '@mdi/js'
import pouchDbMixin from '../../mixins/pouchDbMixin'
import firestoreDbMixin from '../../mixins/firestoreDbMixin'

import dialogAddListContact from './DialogAddListContact.vue'

export default {
  components: {
    dialogAddListContact,
  },
  mixins: [pouchDbMixin, firestoreDbMixin],
  data: () => ({
    dialogList: false,
    resolve: null,
    reject: null,

    dbSettings: null,
    dbRemoteSettings: null,
    contactListSetting: null,
    listContactValue: [],
    listName: [],

    isContact: false,
    contacts: [],
    chats: [],
    contactChat: null,

    selectedList: '',
    saveText: '',

    icons: {
      mdiPlus,
      mdiLabel,
    },
  }),
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  beforeDestroy() {
    this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_SETTINGS)
  },
  methods: {
    async open(saveText = '', list = []) {
      this.dialogList = true
      this.saveText = saveText
      this.listName = []
      list
        .filter(x => x.list_id !== 'default')
        .forEach(data => {
          // //console.log(data, `INI DATA`)
          this.listName.push({
            label: data.list_name,
            value: data,
          })
        })

      // //console.log(this.listName, `INI LIST NAME`)

      // await this.connectSettings(false)
      // await this.loadSetting()

      // this.$eventBus.$on(process.env.VUE_APP_EVENT_UPDATE_SETTINGS, payload => {
      //   this.loadSetting()
      // })

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      const self = this
      self.resolve({
        status: true,
        data: this.selectedList,
      })
      this.dialogList = false
    },
    cancel() {
      this.resolve({
        status: false,
      })
      this.dialogList = false
    },
    addNewLabel() {},

    // async loadSetting() {
    //   const items = await this.loadSettings()

    //   this.contactListSetting = items.find(x => x._id === 'contact_list')
    //   if (!this.contactListSetting) {
    //     // const self = this

    //     await this.updateSettings(
    //       {
    //         _id: 'contact_list',

    //         key: 'contact_list',
    //         value: [],
    //       },
    //       async x => {
    //         this.contactListSetting = await this.loadSettingsById(x.id)
    //         this.listContactValue = this.contactListSetting.value
    //       },
    //     )
    //   } else {
    //     this.listContactValue = this.contactListSetting.value
    //   }
    //   this.listContactValue.forEach(list => {
    //     if (list && list.deleteable) {
    //       this.listName.push({
    //         label: list.list_name,
    //         value: list.id,
    //       })
    //     }
    //   })
    //   if (this.listName.length > 0) this.selectedList = this.listName[0].value
    // },
    // openContactList() {
    //   this.$refs.dialogAddListContact.open('').then(confirm => {
    //     if (confirm.status) {
    //       // const self = this
    //       this.listContactValue.push({
    //         id: this.$nanoid(),
    //         list_name: confirm.name,
    //         deleteable: true,
    //         created_at: new Date().getTime(),
    //         last_modified: new Date().getTime(),
    //       })

    //       this.updateSettings(this.contactListSetting)
    //     }
    //   })
    // },
  },
}
</script>
<style scoped>
.bottom-action {
  display: none;
}
@media (max-width: 536px) {
  .hide-buton {
    display: none;
  }
  .bottom-action {
    display: inherit;
  }
  .block-condition {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
  }
}
</style>

<template>
  <div class="page">
    <div class="marvel-device nexus5">
      <div class="screen">
        <div class="screen-container">
          <div class="chat">
            <div class="chat-container">
              <div class="user-bar">
                <div class="back">
                  <i class="zmdi zmdi-arrow-left"></i>
                </div>
                <v-avatar color="primary" size="40">
                  <v-img :src="avatar" alt="AV"></v-img>
                </v-avatar>
                <div class="name">
                  <span>{{ $t('customer') }}</span>
                  <span class="status">online</span>
                </div>
                <div class="actions more">
                  <i class="zmdi zmdi-more-vert"></i>
                </div>
                <div class="actions attachment">
                  <i class="zmdi zmdi-attachment-alt"></i>
                </div>
                <div class="actions">
                  <i class="zmdi zmdi-phone"></i>
                </div>
              </div>
              <div class="conversation" :style="cssStyle">
                <div class="conversation-container">
                  <div class="message sent">
                    <v-row style="margin-bottom: 5px; width: inherit">
                      <v-col v-for="(item, index) in previewImage" :key="i">
                        <v-img v-if="item.isImg"  max-height="300" max-width="400" contain :src="item.src"> </v-img>
                        <div v-if="!item.isImg" class="message-text">
                          <div class="message-file-icon-container">
                            <div class="message-file-icon-wrapper">
                              <img width="20" :src="previewfile(item)" />
                            </div>
                          </div>
                          <div class="message-file-info">
                            <span class="message-file-name">{{ item.filename }}</span>
                          </div>
                          <div class="message-file-download-link vac-svg-button">
                            <slot name="document-icon" />
                            <svg-icon name="document" />
                            <loader :loading-download="isLoadingDownload" :show="isLoadingDownload"> </loader>
                          </div>
                        </div>
                      </v-col>
                      <div class="message-preview" v-html="previewText"></div>
                      <!-- tempat buttonnya -->
                      <div v-if="previewButton.length !== 0" class="message button sent">
                        <div v-if="previewButtonHeader || previewButtonFooter">
                          <div style="" class="button-header-text">
                            <span>{{ previewButtonHeader ? previewButtonHeader : '' }}</span>
                          </div>
                          <div style="" class="my-2 button-footer-text">
                            <span>{{ previewButtonFooter ? previewButtonFooter : '' }}</span>
                          </div>
                        </div>
                        <hr v-if="previewButtonHeader || previewButtonFooter" class="solid" />

                        <div v-for="button in previewButton" :key="button.index" style="" class="text-center my-2">
                          <v-icon v-if="!button.hasOwnProperty('quickReplyButton')" class="" small color="#47a0c6">
                            {{ button.hasOwnProperty('urlButton') ? icons.mdiOpenInNew : icons.mdiPhone }}
                          </v-icon>
                          <span class="text-button-prev">{{ button.label }}</span>
                        </div>
                      </div>
                      <span class="metadata">
                        <span class="time"></span><span class="tick"><svg id="msg-dblcheck-ack"
                            xmlns="http://www.w3.org/2000/svg" width="16" height="15" x="2063" y="2076">
                            <path
                              d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                              fill="#4fc3f7" />
                          </svg></span>
                      </span>
                    </v-row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '../../plugins/vue-advanced-chat-single/components/SvgIcon/SvgIcon'
import { mdiOpenInNew, mdiPhone } from '@mdi/js'

export default {
  components: { SvgIcon},
  props: {
    messagePreview: {
      type: Object,
      default: () => {
        return { message: '' }
      },
    },
    messagePreviewImg: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    avatar: require('@/assets/images/avatars/3.png'),
    backWA: require('@/assets/images/backgroundWA.jpg'),
    icons: { mdiOpenInNew, mdiPhone },
  }),
  computed: {
    previewText() {
      return this.messagePreview.message.replace(/\n/g, '<br/>')
    },
    previewButton() {
      if (this.messagePreview.listButton) {
        return this.messagePreview.listButton
      }

      return []
    },
    previewButtonHeader() {
      return this.messagePreview.buttonHeader
    },
    previewButtonFooter() {
      return this.messagePreview.buttonFooter
    },
    previewImage() {
      let attach = []
      if (this.messagePreview.attachments !== undefined) {
        attach = this.messagePreview.attachments
          .map(el => el.mimeGroup == 'image' || el.mimeGroup == 'video'?
            {isImg:true, src:'https://fm.prod.marketa.id/unsafe/200x0/'+el.uploadURL.replace(/^https?:\/\//, '').replace(/^fm.prod.marketa.id\/uploads/, 'data')}
            :el
          )
      }

      return attach
    },
    cssStyle() {
      return {
        background: `#efe7dd url(${this.backWA}) no-repeat`,
      }
    },
  },
  methods: {
    previewfile(file) {
      if (file.preview.includes('pdf')) {
        return '/file-manager/icons/application-pdf.svg'
      } else if (file.preview.includes('csv')) {
        return 'https://cdn-icons-png.flaticon.com/512/8242/8242984.png'
      } else if (file.preview.includes('xlsx')) {
        return '/file-manager/icons/application-vnd.ms-excel.svg'
      } else if (file.preview.includes('txt')) {
        return 'https://cdn-icons-png.flaticon.com/512/3979/3979306.png'
      } else if (file.preview.includes('docx')) {
        return '/file-manager/icons/application-msword-template.svg'
      } else {
        return '/file-manager/icons/text.svg'
      }
    },
  },
}
</script>

<style lang="css" scoped>
*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.page {
  width: 100%;
  height: 100%;
  display: block;
  align-items: center;
  justify-content: center;
}

.marvel-device .screen {
  text-align: left;
}

.screen-container {
  height: 100%;
}

/* Chat */

.chat {
  height: calc(100% - 69px);
}

.chat-container {
  height: 100%;
  width: 100%;
}

/* User Bar */

.user-bar {
  height: 55px;
  background: #005e54;
  color: #fff;
  padding: 0 8px;
  font-size: 24px;
  position: relative;
  z-index: 1;
}

.user-bar:after {
  content: '';
  display: table;
  clear: both;
}

.user-bar div {
  float: left;
  transform: translateY(-50%);
  position: relative;
  top: 50%;
}

.user-bar .actions {
  float: right;
  margin: 0 0 0 20px;
}

.user-bar .actions.more {
  margin: 0 12px 0 32px;
}

.user-bar .actions.attachment {
  margin: 0 0 0 30px;
}

.user-bar .actions.attachment i {
  display: block;
  transform: rotate(-45deg);
}

.user-bar .avatar {
  margin: 0 0 0 5px;
  width: 36px;
  height: 36px;
}

.user-bar .avatar img {
  border-radius: 50%;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  display: block;
  width: 100%;
}

.user-bar .name {
  font-size: 17px;
  font-weight: 600;
  text-overflow: ellipsis;
  letter-spacing: 0.3px;
  margin: 0 0 0 8px;
  overflow: hidden;
  white-space: nowrap;
  width: 110px;
}

.user-bar .status {
  display: block;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
}

/* Conversation */

.conversation {
  height: calc(100% - 12px);
  position: relative;
  /* background: #efe7dd url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg") repeat; */
  z-index: 0;
}

.conversation ::-webkit-scrollbar {
  transition: all 0.5s;
  width: 5px;
  height: 1px;
  z-index: 10;
}

.conversation ::-webkit-scrollbar-track {
  background: transparent;
}

.conversation ::-webkit-scrollbar-thumb {
  background: #b3ada7;
}

.conversation .conversation-container {
  height: calc(100% - 68px);
  box-shadow: inset 0 10px 10px -10px #000000;
  overflow-x: hidden;
  padding: 0 16px;
  margin-bottom: 5px;
  min-height: 150px;
}

.conversation .conversation-container:after {
  content: '';
  display: table;
  clear: both;
}

/* Messages */

.message {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 15px;
  padding: 8px;
  position: relative;
  margin: 2px 14px;
  width: 85%;
  max-width: 400px;
  word-wrap: break-word;
  z-index: -1;
  display: flex;
  justify-content: center;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.message:after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
}

.metadata {
  display: inline-block;
  float: right;
  padding: 0 0 0 7px;
  position: absolute;
  bottom: 4px;
  right: 0;
  margin-right: 4px;
}

.metadata .time {
  color: rgba(0, 0, 0, 0.45);
  font-size: 11px;
  display: inline-block;
}

.metadata .tick {
  display: inline-block;
  margin-left: 2px;
  position: relative;
  top: 4px;
  height: 16px;
  width: 16px;
}

.metadata .tick svg {
  position: absolute;
  transition: 0.5s ease-in-out;
}

.metadata .tick svg:first-child {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(180deg);
  transform: perspective(800px) rotateY(180deg);
}

.metadata .tick svg:last-child {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(0deg);
  transform: perspective(800px) rotateY(0deg);
}

.metadata .tick-animation svg:first-child {
  -webkit-transform: perspective(800px) rotateY(0);
  transform: perspective(800px) rotateY(0);
}

.metadata .tick-animation svg:last-child {
  -webkit-transform: perspective(800px) rotateY(-179.9deg);
  transform: perspective(800px) rotateY(-179.9deg);
}

.message:first-child {
  margin: 16px 0 8px;
}

.message.received {
  background: #fff;
  border-radius: 0px 5px 5px 5px;
  float: left;
}

.message.received .metadata {
  padding: 0 0 0 16px;
}

.message-preview {
  font-size: 14px;
  color: #000;
  max-width: 90%;
  margin: 4px 12px;
  white-space: normal;
  /* allow text to wrap */
  word-wrap: break-word;
  /* break long words if necessary */
}

.message.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  left: -10px;
}

.message.sent {
  background: #e1ffc7;
  border-radius: 5px 0px 5px 5px;
  float: right;
}

.message.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #e1ffc7;
  top: 0;
  right: -10px;
}

/* Small Screens */

@media (max-width: 900px) {
  .marvel-device.nexus5 {
    border-radius: 0;
    flex: none;
    padding: 0;
    max-width: none;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }

  .marvel-device>.screen .chat {
    visibility: visible;
  }

  .marvel-device {
    visibility: hidden;
  }
}

.text-button-prev {
  color: #47a0c6;
}

hr.solid {
  border-top: 0.5px solid rgba(184, 184, 184, 0.473);
}

.button-header-text {
  font-size: 16px;
}

.button-footer-text {
  font-size: 13px;
  color: #b3ada7;
}

.message-text {
  background-color: #e9ecef;
  border-radius: 16px;
  padding: 8px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.message-file-icon-container {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
}

.message-file-icon-wrapper {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #3f51b5;
}

.message-download-highlight {
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.11), 0 1px 5px -1px rgba(0, 0, 0, 0.11);
}

.message-file-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 8px;
  width: 9vw;
}

.message-file-name {
  font-weight: bold;
  font-size: 10px;
  color: #000;
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  display: inline-block;
}
</style>

<template>
  <div>
    <!-- dialog config -->
    <v-dialog v-model="dialogEditField" persistent width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ $t('Edit Contact') }}
          </h3>
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-divider />
        <div class="text-center"></div>
        <v-card-text class="height-dialog">
          <v-row class="ma-0 pa-0">
            <v-col cols="12" class="my-0 py-0 px-5">
              <v-text-field
                v-model="listEdit.user.name"
                :rules="[validators.required]"
                class="mt-4"
                outlined
                dense
                :label="$t('contacts.addContactDialog.name')"
              />
              <v-text-field
                v-model="photoLinks"
                outlined
                dense
                :label="$t('contacts.addContactDialog.avatarURL')"
                readonly
                @click="openFileManager"
              />
              <v-text-field v-model="listEdit.nickname" outlined dense :label="`${$t('Nickname')}`" />

              <template>
                <v-combobox
                  v-model="listEdit.user.phoneNumber"
                  :items="listEdit.user.phoneNumber"
                  :rules="[validators.required]"
                  chips
                  label="Phone Number"
                  multiple
                  outlined
                  readonly
                >
                  <template v-slot:selection="{ phones, item, select, selectedPhones }">
                    <v-chip v-bind="phones" :input-value="selectedPhones" @click="select">
                      <strong>{{ item }}</strong>
                    </v-chip>
                  </template>
                </v-combobox>
              </template>

              <template>
                <v-combobox
                  v-model="listEdit.email"
                  :items="listEdit.email"
                  :rules="[validators.email]"
                  chips
                  clearable
                  label="Email"
                  multiple
                  outlined
                  dense
                  deletable-chips
                >
                  <template v-slot:selection="{ emails, item, select, selectedEmail }">
                    <v-chip
                      v-bind="emails"
                      clearable
                      :input-value="selectedEmail"
                      close
                      @click="select"
                      @click:close="remove(item)"
                    >
                      <strong>{{ item }}</strong>
                    </v-chip>
                  </template>
                </v-combobox>
              </template>
              <template>
                <v-select
                  v-model="listEdit.tagNames"
                  :items="listEdit.tagNames"
                  required
                  :menu-props="{ top: false, offsetY: true, display: 'none' }"
                  dense
                  chips
                  deletable-chips
                  label="Add Labels"
                  multiple
                  outlined
                  no-data-text
                  readonly
                  @click="openTags(listEdit)"
                ></v-select>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="" />
        <v-card-actions class="text-end">
          <v-spacer></v-spacer>
          <v-btn class="mr-3" @click="cancel()">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="primary" class="" @click="saveUpdatedContact(listEdit.id)">
            {{ $t('save') }}
          </v-btn>
          <v-progress-circular v-if="loading" indeterminate color="primary" class="mx-4"></v-progress-circular>
        </v-card-actions>
        <v-snackbar v-model="errorTitleValidationSnackbar" color="error" :top="true" style="z-index: 5 !important">
          Please fill out all required fields and make sure you enter a valid email & phone number is required
          <template #action="{ attrs }">
            <v-btn text v-bind="attrs" @click="errorTitleValidationSnackbar = false">
              {{ $t('Close') }}
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="errorPhoneValidationSnackbar.status"
          color="error"
          :top="true"
          style="z-index: 5 !important"
        >
          Your Phone Number {{ errorPhoneValidationSnackbar.phone }} Already Registered
          <template #action="{ attrs }">
            <v-btn text v-bind="attrs" @click="errorPhoneValidationSnackbar = false">
              {{ $t('Close') }}
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </v-dialog>

    <!-- dialog alert -->
    <v-dialog v-model="dialogAlert" width="500">
      <v-card>
        <v-card-title color="error">
          {{ $t('warning') }}
        </v-card-title>
        <v-divider />

        <v-card-text>
          {{ alertText }}
        </v-card-text>
        <v-divider />

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="dialogAlert = false">
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <dialogSetTags ref="dialogSetTags"></dialogSetTags>
    </v-dialog>
  </div>
</template>

<script>
import { default as clientPocketBase } from '@/helpers/pocketbase'
import fileManagerOptions from '@/views/MyAssets/options/optionsFileManager.js'
import { emailValidator, required } from '@core/utils/validation'
import { mdiDeleteOutline, mdiPlus } from '@mdi/js'
import axios from 'axios'
import { fileManagerModal } from 'mini-file-manager'
import pouchDbMixin from '../../mixins/pouchDbMixin'
import dialogSetTags from './DialogSetTags.vue'

export default {
  setup() {
    return {
      validators: {
        required,
        emailValidator,
      },
    }
  },
  name: 'EditContact',
  component: {
    dialogSetTags,
  },
  mixins: [pouchDbMixin],
  props: {
    selectedTags: { type: Array, default: () => [] },
    allTags: { type: Array, default: () => [] },
    selected: { type: Object, default: () => ({}) },
  },
  data: () => ({
    dialogEditField: false,
    dialogAlert: false,
    resolve: null,
    reject: null,
    name: '',
    listEdit: {
      user: {
        name: '',
        phoneNumber: [],
      },
      nickname: '',
      tagNames: '',
      allTagId: '',
      avatar: '',
      assignTo: '',
      email: [],
    },
    itemsField: [],
    mdiPlus,
    mdiDeleteOutline,
    alertText: '',
    tags: [],
    errorTitleValidationSnackbar: false,
    errorPhoneValidationSnackbar: false,
    oldPhoneNumber: [],
    action: '',
    selectedTableData: [],
    photoLinks: '',
    groupingTags: [],
    loading: false,
  }),
  computed: {
    async fetchAllPhoneNumber() {
      const getContacts = await clientPocketBase.collection('crm_contacts').getFullList(200, {
        expand: 'tags',
      })
      const phoneNumber = []
      for (let i = 0; i < getContacts.length; i++) {
        const element = getContacts[i]
        if (Object.keys(element.phone).length != 0) {
          const getPhone = element.phone.phone_number
          for (let j = 0; j < getPhone.length; j++) {
            const phone = getPhone[j]
            phoneNumber.push(phone)
          }
        }
      }

      return phoneNumber
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  watch: {
    selectedTags() {
      // console.log(this.selectedTags, '<<tags')
      this.listEdit.tagNames = this.selectedTags
    },
  },
  methods: {
    isOldEntry(item) {
      console.log(`ITEM:${JSON.stringify([this.oldPhoneNumber, item])}`)

      // Replace this logic with your own to determine which entries are old
      return this.oldPhoneNumber.findIndex(old => old === item) == -1
    },
    openFileManager() {
      fileManagerModal(fileManagerOptions(this.user.sub_id), this.saveFile)
    },
    async saveFile(data) {
      // console.log('sayang', data);
      const images = Promise.all(
        data.map(async el => {
          const res = `https://fm.prod.marketa.id/uploads/${el.uploadRelativePath}`
          this.photoLinks = res
        }),
      )
    },
    open(data, action) {
      this.dialogEditField = true
      this.dialogAlert = false
      this.alertText = ''
      this.action = action

      if (action === 'grouping') {
        const first = data[0]

        first.tags = first.tags || []
        first.hashTag = first.hashTag || []
        first.tagNames = first.tagNames || []
        first.allTagId = first.allTagId || []

        for (let j = 1; j < data.length; j++) {
          const dt = data[j]
          first.allTagId.push(...(dt.allTagId || []))
          first.tags.push(...(dt.tags || []))
          first.hashTag.push(...(dt.hashTag || []))
          first.tagNames.push(...(dt.tagNames || []))
          if (dt.user.phoneNumber != '') first.user.phoneNumber += `,${dt.user.phoneNumber}`
          if (dt.email != '') first.email += `,${dt.email}`
        }
        this.selectedTableData = data
        data = first

        data.tags = data.tags.filter((item, index) => {
          return index === data.tags.findIndex(obj => obj.id === item.id)
        })
        data.hashTag = data.hashTag.filter((item, index) => {
          return index === data.hashTag.findIndex(obj => obj.id === item.id)
        })
        data.allTagId = data.allTagId.filter((item, index) => {
          return data.allTagId.indexOf(item) === index
        })
        data.tagNames = data.tagNames.filter((item, index) => {
          return data.tagNames.indexOf(item) === index
        })
      }
      this.listEdit = data
      let newPhoneArray =
        typeof data.user.phoneNumber === 'string'
          ? data.user.phoneNumber.split(',') || data.user.phoneNumber.join()
          : data.user.phoneNumber.join()
      let newEmailArray =
        typeof data.email === 'string' ? data.email.split(',') || data.email.join() : data.email.join()

      newPhoneArray = newPhoneArray.filter((item, index) => {
        return newPhoneArray.indexOf(item) === index
      })
      newEmailArray = newEmailArray.filter((item, index) => {
        return newEmailArray.indexOf(item) === index
      })
      this.listEdit.user.phoneNumber = newPhoneArray
      this.listEdit.email = newEmailArray
      this.oldPhoneNumber = this.listEdit.user.phoneNumber
      this.listEdit.id = data.id
      this.listEdit.nickname = data.nickname
      this.photoLinks = data.avatar

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    openTags(contactId) {
      // this.$refs.dialogSetTags.open(contactId)
      this.$emit('open-tags', contactId)
    },
    async saveGroupingContact() {
      // this.dialogEditField = false
      this.tableLoading = true

      // for (let j = 0; j < this.selectedTableData.length; j++) {
      //   const selected = this.selectedTableData[j]
      //   if (j !== 0) {
      //     // if (selected.allTagId.length) {
      //     //   for (let i = 0; i < selected.allTagId.length; i++) {
      //     //     const tagId = selected.allTagId[i]
      //     //     this.groupingTags.push(tagId)
      //     //     const idTag = this.$Md5Hash(`${tagId}-${selected.id}`)
      //     //     // //console.log(idTag, 'ini idtek')
      //     //     try {
      //     //       await clientPocketBase.collection('crm_contact_tags').delete(idTag, { $autoCancel: false })
      //     //     } catch (err) {
      //     //       console.log(err, 'err1')
      //     //     }
      //     //   }
      //     // }
      //     try {
      //       const response = await clientPocketBase.collection('crm_form_response').getFullList(200, {
      //         filter: `contacts = '${selected.id}'`,
      //         $autoCancel: false,
      //       })

      //       if (response.length !== 0) {
      //         for (let i = 0; i < response.length; i++) {
      //           const id = response[i].id
      //           await clientPocketBase.collection('crm_form_response').delete(id, { $autoCancel: false })
      //         }
      //       }
      //     } catch (err) {
      //       console.log(err, 'err2')
      //     }
      //     try {
      //       // const allEmails = [];

      //       if (selected.email) {
      //         // Check if selected.email is not null or undefined
      //         if (typeof selected.email === 'string') {
      //           allEmail.push(selected.email)
      //         } else if (Array.isArray(selected.email)) {
      //           selected.email.forEach(email => {
      //             if (typeof email === 'string' && email !== '') {
      //               allEmail.push(email)
      //             }
      //           })
      //         }
      //       }
      //       try {
      //         await clientPocketBase.collection('crm_contacts').delete(selected.id, { $autoCancel: false })
      //       } catch (err) {
      //         console.log(err, '<<delete err contacts')
      //       }
      //       // const update = await clientPocketBase.collection('crm_contacts').update(
      //       //   this.selectedTableData[0].id,
      //       //   {
      //       //     email: { email: allEmail },
      //       //   },
      //       //   { $autoCancel: false },
      //       // )
      //     } catch (err) {
      //       console.log(err, 'err3')
      //     }
      //   } else {
      //     if (selected.email !== '') {
      //       allEmail.push(selected.email)
      //       if (selected.email.length > 0) {
      //         for (let i = 0; i < selected.email; i++) {
      //           allEmail.push(selected.email[i].toString())
      //         }
      //       }
      //     }
      //     for (let j = 0; j < this.listEdit.user.phoneNumber.length; j++) {
      //       const phone = this.listEdit.user.phoneNumber[j]
      //       const id = this.$Md5Hash(`${this.user.sub_id}-${phone}`)
      //       const deletePhone = await clientPocketBase
      //         .collection('crm_contact_phones')
      //         .delete(id, { $autoCancel: false })
      //     }
      //     // console.log(selected.id, allTags, allEmail, '<<all update')
      //     const update = await clientPocketBase.collection('crm_contacts').update(
      //       selected.id,
      //       {
      //         name: selected.name,
      //         avatar: selected.avatar,
      //         nickname: selected.nickname,
      //         email: { email: allEmail },
      //         subId: this.user.sub_id,
      //         tags: null,
      //       },
      //       { $autoCancel: false },
      //     )
      //   }
      // }
      // const update = await clientPocketBase.collection('crm_contacts').update(
      //   this.selectedTableData[0].id,
      //   {
      //     email: { email: allEmail },
      //     tags: this.groupingTags,
      //   },
      //   { $autoCancel: false },
      // )

      const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}grouping/add`
      const payload = {
        subId: this.user.sub_id,
        allPhone: this.listEdit.user.phoneNumber,
        selectedTableData: this.selectedTableData,
      }
      const { data: labels } = await axios.post(endpoint, payload)
      if (!labels.success) {
        this.tableLoading = false
      }
    },
    async saveUpdatedContact(contactId) {
      this.loading = true
      if (this.listEdit.user.phoneNumber.length == 0 || this.listEdit.user.name === '') {
        this.errorTitleValidationSnackbar = true
      } else {
        const phoneRegex = /^\d+$/
        const allNumbersValid = this.listEdit.user.phoneNumber.every(phone => {
          return phoneRegex.test(phone)
        })
        if (!allNumbersValid) {
          this.errorTitleValidationSnackbar = true
        }
      }
      if (!this.errorTitleValidationSnackbar) {
        const duplicateAlert = []
        let isExist
        let isSameWithOldValue
        let isSuccessEdit = true
        const allPhone = []
        const selected = Object.keys(this.selected)

        // const allPhoneNumber = await this.fetchAllPhoneNumber
        this.tableLoading = true
        if (this.selectedTableData) {
          await this.saveGroupingContact()

          // this.$emit('fetch-contact')
        }
        for (let j = 0; j < this.listEdit.user.phoneNumber.length; j++) {
          const inputPhone = this.listEdit.user.phoneNumber[j]
          const formatInputPhone = inputPhone.split(' ').join('').replace(/^0+/, '62')
          const getAllContactByPhone = await clientPocketBase.collection('crm_contact').getFullList(200, {
            expand: 'assign_to',
            filter: `phone_number = '${formatInputPhone}'`,
            $autoCancel: false,
          })
          for (let i = 0; i < getAllContactByPhone.length; i++) {
            const idContact = getAllContactByPhone[i].id
            const updateContact = await clientPocketBase
              .collection('crm_contact')
              .update(
                idContact,
                { name: this.listEdit.user.name, profile_picture: this.photoLinks },
                { $autoCancel: false },
              )
          }
          const idPhone = this.$Md5Hash(`${this.user.sub_id}-${formatInputPhone}`)
          allPhone.push(formatInputPhone)
          if (!isNaN(formatInputPhone)) {
            try {
              const getPhoneContacts = await clientPocketBase
                .collection('crm_contact_phones')
                .getFirstListItem(`phone_number = '${formatInputPhone}'`, {
                  expand: 'contact_id',
                  $autoCancel: false,
                })
              const checkOldPhone = this.oldPhoneNumber.findIndex(old => old === formatInputPhone)
              if (getPhoneContacts) {
                if (checkOldPhone == -1) {
                  duplicateAlert.push(formatInputPhone)
                  this.errorPhoneValidationSnackbar = {
                    status: true,
                    phone: duplicateAlert.toString().split(',').join(', '),
                  }
                  isSuccessEdit = false
                  isExist = true
                  isSameWithOldValue = false
                } else {
                  isSameWithOldValue = true
                }
              }
            } catch (err) {
              if (err.status == 404) {
                isExist = false
                isSameWithOldValue = false
              } else {
                console.log(err)
              }
            }
          }
          if (!isExist && !isSameWithOldValue) {
            this.tableLoading = true
            try {
              const newPhoneNumber = await clientPocketBase.collection('crm_contact_phones').create(
                {
                  id: this.$Md5Hash(`${this.user.sub_id}-${formatInputPhone}`),
                  phone_number: formatInputPhone,
                  contact_id: contactId,
                  sub_id: this.user.sub_id,
                },
                { $autoCancel: false },
              )
            } catch (err) {
              const idPhone = this.$Md5Hash(`${this.user.sub_id}-${formatInputPhone}`)
              const updatePhoneNumber = await clientPocketBase.collection('crm_contact_phones').update(
                idPhone,
                {
                  phone_number: formatInputPhone,
                  contact_id: contactId,
                  sub_id: this.user.sub_id,
                },
                { $autoCancel: false },
              )
            }

            // this.dialogEditField = false
          }
          if (isExist && !isSameWithOldValue) {
            const idPhone = this.$Md5Hash(`${this.user.sub_id}-${formatInputPhone}`)
            const updatePhoneNumber = await clientPocketBase.collection('crm_contact_phones').update(
              idPhone,
              {
                phone_number: formatInputPhone,
                contact_id: contactId,
                sub_id: this.user.sub_id,
              },
              { $autoCancel: false },
            )

            // this.dialogEditField = false
          }
        }
        console.log(isExist, isSameWithOldValue, 'apa ini<')
        if (!isExist || isSameWithOldValue) {
          const obj = {
            // name: this.listEdit.name,
            // avatar: this.photoLinks,
            nickname: this.listEdit.nickname,
            email: { email: this.listEdit.email },
            subId: this.user.sub_id,

            // tags: selected.filter(key => this.selected[key] !== null),
            phoneNumber: allPhone,
            editedName: this.listEdit.user.name,
            editedAvatar: this.photoLinks,
          }
          if (selected && selected.length) {
            obj.tags = selected.filter(key => this.selected[key] !== null)
          }
          const update = await clientPocketBase
            .collection('crm_contacts')
            .update(contactId, obj, { $autoCancel: false })
        }

        try {
          if (selected.length) {
            await clientPocketBase.collection('crm_contacts').update(
              contactId,
              {
                tags: selected.filter(key => this.selected[key] !== null),
              },
              { $autocancel: false },
            )
            for (let i = 0; i < selected.length; i++) {
              const id = this.$Md5Hash(`${selected[i]}-${contactId}`)
              try {
                await clientPocketBase.collection('crm_contact_tags').delete(id, { $autocancel: false })
              } catch (err) {}
              if (this.selected[selected[i]] !== null) {
                const createContactTag = await clientPocketBase.collection('crm_contact_tags').create(
                  {
                    id,
                    tag: selected[i],
                    variant: this.selected[selected[i]].value,
                    contacts: contactId,
                    subId: this.user.sub_id,
                    rawData: Object.fromEntries(Object.entries(this.selected).filter(([_, v]) => v != null)),
                  },
                  { $autocancel: false },
                )
              }
            }
          }
        } catch (err) {
          console.log(err)
        }
        if (isSuccessEdit) {
          this.photoLinks = ''
          this.$emit('fetch-contact')
        }
        this.tableLoading = false
        this.dialogEditField = false
      }
      this.selectedTableData = []
      this.groupingTags = []
      this.loading = false
    },
    async remove(item) {
      const filterPhone = this.listEdit.user.phoneNumber.indexOf(item)

      if (filterPhone !== -1) {
        this.listEdit.user.phoneNumber.splice(filterPhone, 1)
        const deletePhoneNumber = await clientPocketBase.collection('crm_contact_phones').delete(this.$Md5Hash(item))
      }
      if (this.listEdit.email.length) {
        const filterEmail = this.listEdit.email.indexOf(item)
        if (filterEmail !== -1) {
          this.listEdit.email.splice(filterEmail, 1)
        }
      }

      if (this.listEdit.tags && this.listEdit.tags.length) {
        const filterTag = this.listEdit.tags.indexOf(item)
        if (filterTag !== -1) {
          this.listEdit.tags.splice(filterTag, 1)
        }
      }
    },
    cancel() {
      this.dialogEditField = false
      this.photoLinks = ''
      this.$emit('fetch-contact')
    },
    newField() {
      this.listEdit.push({
        field: '',
        listEdit: [],
      })
    },
    deleteField(idx) {
      this.listEdit.splice(idx, 1)
    },
    showAlert(warningText) {
      this.alertText = warningText
      this.dialogAlert = true
    },
  },
}
</script>
<style scoped>
.bottom-action {
  display: none;
}
.height-dialog {
  max-height: 400px;
  overflow-y: auto;
}
@media (max-width: 536px) {
  .hide-buton {
    display: none;
  }
  .bottom-action {
    display: inherit;
  }
  .block-condition {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
  }
}
</style>

<template>
  <v-card>
    <!-- dialog select list -->
    <v-dialog v-model="dialogOperator" width="900px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ $t('Select Form Survey to Add Response') }}
          </h3>
          <v-spacer></v-spacer>
        </v-card-actions>
        <!-- <v-divider /> -->
        <div class="px-2 mx-3 mt-3">
          <v-card elevation="0" outlined height="373" width="823" class="mb-8 mx-auto py-5 px-8 scroll">
            <v-data-table
              item-key="id"
              :loading="loadingTable"
              :headers="customHeaders"
              :items="customDataForm"
              class="text-no-wrap"
              :footer-props="{
                'items-per-page-text': $t('rowsPerPageLabel'),
                'items-per-page-options': [5, 10, 25, 50, 75, 100],
              }"
            >
              <template #[`item.action`]="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" dense icon @click="addResponse(item)">
                      <v-icon>{{ icons.mdiPlus }}</v-icon>
                    </v-btn>
                  </template>
                </v-menu>
              </template>
              <template #[`item.tags`]="{ item }">
                <v-tooltip v-for="(tag, idx) in item.tags" :key="idx" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon fab v-bind="attrs" :color="tag.color" v-on="on">
                      {{ icons.mdiLabelVariant }}
                    </v-icon>
                  </template>
                  <span>Tag Name : {{ tag.name }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </v-card>
      <v-snackbar
        v-model="errorResponseValidationSnackbar"
        color="error"
        :top="true"
        style="z-index: 100000 !important"
      >
        Response Must Be Filled
        <template #action="{ attrs }">
          <v-btn text v-bind="attrs" @click="errorResponseValidationSnackbar = false"> {{ $t('Close') }} </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        v-model="successResponseValidationSnackbar"
        color="primary"
        :top="true"
        style="z-index: 100000 !important"
      >
        Response successfully created
        <template #action="{ attrs }">
          <v-btn text v-bind="attrs" @click="successResponseValidationSnackbar = false"> {{ $t('Close') }} </v-btn>
        </template>
      </v-snackbar>
      <!-- <v-snackbar v-model="successValidation" color="success" :top="true" style="z-index: 5 !important">
        Your responses has been successfully created!
        <template #action="{ attrs }">
          <v-btn text v-bind="attrs" @click="successValidation = false"> {{ $t('Close') }} </v-btn>
        </template>
      </v-snackbar> -->
    </v-dialog>
  </v-card>
</template>

<script>
import { default as clientPocketBase } from '@/helpers/pocketbase'
import { mdiLabel, mdiLabelVariant, mdiPlus } from '@mdi/js'
import pouchDbMixin from '../../mixins/pouchDbMixin'
import dialogAddResponse from '../component/DialogAddResponse.vue'

export default {
  mixins: [pouchDbMixin],
  components: {
    dialogAddResponse,
  },
  data: () => ({
    dialogOperator: false,
    resolve: null,
    reject: null,

    loadingTable: false,
    customDataForm: [],
    customHeaders: [
      {
        text: 'Add Response',
        value: 'action',
      },
      {
        text: 'Form Name',
        value: 'name',
      },
      {
        text: 'Content Amount',
        value: 'amount',
      },
      {
        text: 'Label',
        value: 'tags',
      },
      {
        text: 'Created At',
        value: 'createdAt',
      },
      {
        text: 'Last Update',
        value: 'updatedAt',
      },
    ],
    icons: {
      mdiLabel,
      mdiLabelVariant,
      mdiPlus,
    },
  }),

  props: {
    errorResponseValidationSnackbar: { type: Boolean },
    successResponseValidationSnackbar: { type: Boolean },
  },
  async mounted() {
    this.fetchForm()
    clientPocketBase.collection('crm_form_response').subscribe('*', e => this.fetchForm())
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  beforeDestroy() {
    this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_SETTINGS)
  },
  methods: {
    async fetchForm() {
      this.loadingTable = true
      const formData = await clientPocketBase.collection('crm_contact_form').getFullList(5, {
        expand: `tags`,
        sort: `-updated`,
        $autoCancel: false,
      })
      let tempAmmount = []

      for (let i = 0; i < formData.length; i++) {
        const el = formData[i]

        let responseData = await clientPocketBase.collection('crm_form_response_data').getFullList(200, {
          filter: `form = '${el.id}'`,
          $autoCancel: false,
        })

        tempAmmount.push(responseData.length)
      }

      const formattedData = formData.map((el, idx) => {
        return {
          id: el.id,
          name: el.name,
          amount: `${tempAmmount[idx]} responden`,
          tags: el.tags,
          survey_json: el.survey_json,
          raw_data: el.raw_data,
          from_cms: el.from_cms,
          callback_urls: el.callback_urls,
          is_singleton: el.is_singleton,
          createdAt: this.dateFormater(el.created),
          updatedAt: this.dateFormater(el.updated),
        }
      })

      this.customDataForm = formattedData
      this.loadingTable = false
    },
    dateFormater(date) {
      var d = new Date(this.$moment(this.$moment.utc(date).toDate()).local().format())
      let day = d.getDate()
      let month = d.toLocaleString('default', { month: 'long' })
      let year = d.getFullYear()
      let time

      if (d.toLocaleTimeString().length === 8) {
        time = d
          .toLocaleTimeString('id-ID', {
            hour12: false,
          })
          .slice(0, 6)
      } else {
        time = d
          .toLocaleTimeString('id-ID', {
            hour12: false,
          })
          .slice(0, 5)
      }

      return `${day} ${month} ${year} | ${time}`
    },
    async open() {
      this.dialogOperator = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    async addResponse(item) {
      //console.log(item, '<<vira ini item')
      // let foundData = await clientPocketBase.collection('crm_contact_form').getOne( formId)
      this.$emit('save-response', item)
    },
  },
}
</script>
<style scoped>
.scroll {
  overflow-y: scroll;
}
</style>

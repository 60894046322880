class TimeFormater {
  static dateFormater(dt) {
    let formatDate = `${(dt.getMonth() + 1).toString().padStart(2, '0')}-${dt
      .getDate()
      .toString()
      .padStart(2, '0')}-${dt
      .getFullYear()
      .toString()
      .padStart(4, '0')} ${dt
      .getHours()
      .toString()
      .padStart(2, '0')}:${dt
      .getMinutes()
      .toString()
      .padStart(2, '0')}:${dt
      .getSeconds()
      .toString()
      .padStart(2, '0')}`
    return formatDate
  }
  static msToTime(duration) {
    let milliseconds = Math.floor((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24)
    hours = hours < 10 ? '0' + hours : hours
    minutes = minutes < 10 ? '0' + minutes : minutes
    seconds = seconds < 10 ? '0' + seconds : seconds
    return hours + ':' + minutes + ':' + seconds + '.' + milliseconds
  }
  static numbericdate(date) {
    return new Date(date).getTime() / 1000
  }
  static dateFormaterYMD(dt) {
    let formatDate = `${dt
      .getFullYear()
      .toString()
      .padStart(4, '0')}-${(dt.getMonth() + 1).toString().padStart(2, '0')}-${dt
      .getDate()
      .toString()
      .padStart(2, '0')}`
    return formatDate
  }
}
module.exports = TimeFormater

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-dialog',{attrs:{"width":"900px"},model:{value:(_vm.dialogOperator),callback:function ($$v) {_vm.dialogOperator=$$v},expression:"dialogOperator"}},[_c('v-card',[_c('v-card-actions',[_c('h3',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('Select Form Survey to Add Response'))+" ")]),_c('v-spacer')],1),_c('div',{staticClass:"px-2 mx-3 mt-3"},[_c('v-card',{staticClass:"mb-8 mx-auto py-5 px-8 scroll",attrs:{"elevation":"0","outlined":"","height":"373","width":"823"}},[_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"item-key":"id","loading":_vm.loadingTable,"headers":_vm.customHeaders,"items":_vm.customDataForm,"footer-props":{
              'items-per-page-text': _vm.$t('rowsPerPageLabel'),
              'items-per-page-options': [5, 10, 25, 50, 75, 100],
            }},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"dense":"","icon":""},on:{"click":function($event){return _vm.addResponse(item)}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))])],1)]}}],null,true)})]}},{key:"item.tags",fn:function(ref){
            var item = ref.item;
return _vm._l((item.tags),function(tag,idx){return _c('v-tooltip',{key:idx,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"fab":"","color":tag.color}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiLabelVariant)+" ")])]}}],null,true)},[_c('span',[_vm._v("Tag Name : "+_vm._s(tag.name))])])})}}],null,true)})],1)],1)],1),_c('v-snackbar',{staticStyle:{"z-index":"100000 !important"},attrs:{"color":"error","top":true},scopedSlots:_vm._u([{key:"action",fn:function(ref){
            var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.errorResponseValidationSnackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('Close'))+" ")])]}}]),model:{value:(_vm.errorResponseValidationSnackbar),callback:function ($$v) {_vm.errorResponseValidationSnackbar=$$v},expression:"errorResponseValidationSnackbar"}},[_vm._v(" Response Must Be Filled ")]),_c('v-snackbar',{staticStyle:{"z-index":"100000 !important"},attrs:{"color":"primary","top":true},scopedSlots:_vm._u([{key:"action",fn:function(ref){
            var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.successResponseValidationSnackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('Close'))+" ")])]}}]),model:{value:(_vm.successResponseValidationSnackbar),callback:function ($$v) {_vm.successResponseValidationSnackbar=$$v},expression:"successResponseValidationSnackbar"}},[_vm._v(" Response successfully created ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-dialog v-model="openDialog" :width="$vuetify.breakpoint.xs ? '100%' : '60%'">
    <v-card>
      <v-card-actions>
        <h3 v-if="title === 'add'" class="font-weight-bold">
          {{ $t('Add Response') }}
        </h3>
        <h3 v-else class="font-weight-bold">
          {{ $t('Edit Response') }}
        </h3>
      </v-card-actions>
      <v-divider />
      <v-card-text>
        <v-select
          v-if="title === 'add'"
          v-model="contacts"
          :items="selectedContact.length ? selectedContact : contactsData"
          item-text="name"
          item-value="id"
          label="Choose contact"
          multiple
          chips
          dense
          outlined
          append-icon=""
          class="mx-4"
          clearable
          required
          no-data-text=""
        >
          <template v-slot:prepend-item>
            <v-progress-circular
              v-if="isLoading"
              :size="26"
              :width="4"
              indeterminate
              class="loader"
            ></v-progress-circular>
          </template>
          <template v-slot:selection="data">
            <v-chip
              :key="JSON.stringify(data.item)"
              clearable
              close
              small
              class="py-3 px-2 my-2 mx-1"
              @click:close="remove(data.item.id)"
            >
              <strong style="font-size: 12px">{{ contactName ? contactName : data.item.name }}</strong>
            </v-chip>
          </template>
        </v-select>
        <v-select
          v-else
          v-model="selectedResponse"
          :items="surveyDetails.response_data"
          item-text="responseName"
          item-value="id"
          label="Choose response"
          dense
          return-object
          outlined
          class="mx-4"
          required
        />
      </v-card-text>

      <v-divider />
      <div class="mx-4">
        <Survey :survey="surveyResponse" />
      </div>
      <v-divider />
      <v-card-actions class="mt-2 mb-4 mx-1">
        <v-spacer></v-spacer>
        <v-btn color="#1F979E" class="text-white" @click="saveResponse()">
          {{ $t('save') }}
        </v-btn>
        <v-snackbar v-model="errorTitleValidationSnackbar" color="error" :top="true" style="z-index: 5 !important">
          Contact <strong v-for="contact in this.validatedContacts" :key="contact">{{ contact }}</strong> already filled
          this response
          <template #action="{ attrs }">
            <v-btn text v-bind="attrs" @click="errorTitleValidationSnackbar = false">
              {{ $t('Close') }}
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar v-model="errorContactValidationSnackbar" color="error" :top="true" style="z-index: 5 !important">
          Contact must be filled
          <template #action="{ attrs }">
            <v-btn text v-bind="attrs" @click="errorContactValidationSnackbar = false">
              {{ $t('Close') }}
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar v-model="errorResponseValidationSnackbar" color="error" :top="true">
          Response Must Be Filled
          <template #action="{ attrs }">
            <v-btn text v-bind="attrs" @click="errorResponseValidationSnackbar = false">
              {{ $t('Close') }}
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { default as clientPocketBase } from '@/helpers/pocketbase'
import axios from 'axios'
import { Model, StylesManager } from 'survey-core'
import 'survey-core/defaultV2.min.css'
import { Survey } from 'survey-vue-ui'

StylesManager.applyTheme('defaultV2')

export default {
  name: 'ResponseSurvey',
  components: {
    Survey,
  },
  props: ['formData', 'responseData', 'contactIdResponse'],
  data() {
    return {
      validatedContacts: [],
      errorTitleValidationSnackbar: false,
      errorContactValidationSnackbar: false,
      errorResponseValidationSnackbar: false,
      openDialog: false,
      surveyResponse: '',
      selectedResponse: '',
      contactsData: [],
      surveyJson: {
        elements: [],
      },
      contacts: [],
      surveyDetails: null,
      title: 'add',
      formId: '',
      idFormRoomChat: '',
      tags: [],
      responseId: [],
      selectedContact: [],
      contactName: '',
      isLoading: false,
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  watch: {
    errorResponseValidationSnackbar() {
      console.log(this.errorResponseValidationSnackbar, 'dari watcher di response')
    },
    selectedResponse(newVal) {
      if (newVal) {
        this.surveyResponse.data = newVal.response
        this.responseId = newVal.id
      }
    },
    openDialog(newVal) {
      if (!newVal) {
        this.selectedResponse = ''
      }
    },
  },
  async mounted() {},
  beforeCreate() {},
  beforeDestroy() {
    this.surveyResponse = null
  },
  methods: {
    open(response, tags, idForm) {
      if (this.$route.query.contactId && this.$route.query.contactName) {
        this.selectedContact.push({ id: this.$route.query.contactId, name: this.$route.query.contactName })
        this.contactName = this.$route.query.contactName
      } else if (this.$route.query.contactId && !this.$route.query.contactName) {
        this.selectedContact = JSON.parse(this.$route.query.contactId)
      } else {
        this.selectedContact = []
        this.contactName = ''
      }
      this.contacts = []
      this.tags = tags
      this.idFormRoomChat = idForm
      const parents = this

      // parents.fetchSurvey()
      this.surveyJson.elements = this.formData.survey_json
      this.surveyResponse = new Model(this.surveyJson)
      this.surveyResponse.showNavigationButtons = false
      this.fetchContacts()
      parents.openDialog = true
      if (response) {
        response.response_data.forEach((el, i) => {
          el.responseName = `Answer ${i + 1}`
        })

        this.surveyDetails = response
        if (response.response_data.length === 1) {
          this.selectedResponse = response.response_data[0]
        }
        this.contacts = [response.id]
        this.title = 'edit'
      } else {
        this.title = 'add'
      }
    },
    async saveResponse() {
      const foundResponse = []
      if (!this.contacts.length) {
        this.errorContactValidationSnackbar = true
      } else {
        if (this.formData.is_singleton) {
          this.contacts.forEach(contactId => {
            const matchContact = this.responseData.filter(response => response.id === contactId)
            if (matchContact.length) {
              matchContact.forEach(contact => {
                foundResponse.push(contact.user.name)
              })
            }
          })
        }
        if (foundResponse.length && this.title === 'add') {
          this.errorTitleValidationSnackbar = true
          this.validatedContacts = foundResponse.map((response, index) => {
            if (index === foundResponse.length - 1) {
              return `${response} `
            }

            return `${response}, `
          })
        } else {
          if (this.surveyResponse.data) {
            let countRequired = 0
            let countResponseRequired = 0
            for (let i = 0; i < this.surveyJson.elements.length; i++) {
              const question = this.surveyJson.elements[i]
              let requiredQuestion
              if (question.isRequired) {
                requiredQuestion = question.name
                countRequired++
              }
              if (requiredQuestion) {
                for (const question in this.surveyResponse.data) {
                  if (requiredQuestion === question) {
                    countResponseRequired++
                  }
                }
              }
            }

            const isPassable = countRequired === countResponseRequired

            if (!isPassable) {
              this.errorResponseValidationSnackbar = true
            } else if (this.title === 'add') {
              try {
                await this.contactTagsMaker()
              } catch (error) {
                console.log(error)
              }

              for (let i = 0; i < this.contacts.length; i++) {
                const contact = this.contacts[i]

                let foundResponse
                let response_form

                try {
                  foundResponse = await clientPocketBase
                    .collection('crm_form_response')
                    .getOne(this.$Md5Hash(`${contact}-${this.user.sub_id}`), {
                      $autoCancel: false,
                    })
                } catch (err) {
                  console.log(
                    `🥶🥶🥶🥶 ~ フ ク ロ ウ file: DialogAddResponse.vue:314 ~ フ ク ロ ウ saveResponse ~ フ ク ロ ウ err`,
                    err,
                  )
                }

                if (foundResponse) {
                  const { id: idResponseData } = await clientPocketBase.collection('crm_form_response_data').create(
                    {
                      response: this.surveyResponse.data,
                      subId: this.user.sub_id,
                      form_response: foundResponse.id,
                      form: this.idFormRoomChat ? this.idFormRoomChat : this.$route.params.formId,
                    },
                    {
                      $autoCancel: false,
                    },
                  )
                  response_form = idResponseData

                  foundResponse.responses.push(idResponseData)

                  await clientPocketBase.collection('crm_form_response').update(
                    foundResponse.id,
                    {
                      responses: foundResponse.responses,
                    },
                    {
                      $autoCancel: false,
                    },
                  )
                } else {
                  const { id } = await clientPocketBase.collection('crm_form_response').create(
                    {
                      id: this.$Md5Hash(`${contact}-${this.user.sub_id}`),
                      form: this.$route.params.formId,
                      response_data: this.surveyResponse.data,
                      subId: this.user.sub_id,
                      contacts: contact,
                      type: 'Response',
                      user: this.user.id,
                    },
                    {
                      $autoCancel: false,
                    },
                  )

                  const { id: idResponseData } = await clientPocketBase.collection('crm_form_response_data').create(
                    {
                      response: this.surveyResponse.data,
                      subId: this.user.sub_id,
                      form_response: id,
                      form: this.$route.params.formId,
                    },
                    {
                      $autoCancel: false,
                    },
                  )

                  response_form = idResponseData

                  await clientPocketBase.collection('crm_form_response').update(
                    id,
                    {
                      responses: [idResponseData],
                    },
                    {
                      $autoCancel: false,
                    },
                  )
                }

                try {
                  if (this.formData.from_cms) {
                    await this.$axios.post(this.formData.callback_urls.add, {
                      subId: this.user.sub_id,
                      formId: response_form,
                      customerId: contact,
                      answer: this.surveyResponse.data,
                    })

                    // const findCustomer = await clientPocketBase
                    //   .collection('cms_customers_dev')
                    //   .getFirstListItem(`contact = '${contact}'`)

                    // await clientPocketBase.collection('cms_survey_appointments_dev').create({
                    //   customer: findCustomer.id,
                    //   response_form,
                    //   ca: this.surveyResponse.data.ca,
                    //   cluster:
                    //     this.surveyResponse.data.projectCluster.split(' - ')[
                    //       this.surveyResponse.data.projectCluster.split(' - ').length - 1
                    //     ],
                    //   description: this.surveyResponse.data.keterangan,
                    //   subId: this.user.sub_id,
                    //   raw_response: this.surveyResponse.data,
                    //   survey_date: new Date(this.surveyResponse.data.tanggalSurvey).toISOString(),
                    // })
                  }
                } catch (err) {
                  console.log(
                    `🥶🥶🥶🥶 ~ フ ク ロ ウ file: DialogAddResponse.vue:344 ~ フ ク ロ ウ saveResponse ~ フ ク ロ ウ err`,
                    err,
                  )

                  // const { id } = await clientPocketBase.collection('cms_customers_dev').create({
                  //   contact: this.contactsData.find(el => el.id === contact)?.id,
                  //   status: 'Belum Survey',
                  // })

                  // await clientPocketBase.collection('cms_survey_appointments_dev').create({
                  //   customer: id,
                  //   response_form,
                  //   ca: this.surveyResponse.data.ca,
                  //   cluster:
                  //     this.surveyResponse.data.projectCluster.split(' - ')[
                  //       this.surveyResponse.data.projectCluster.split(' - ').length - 1
                  //     ],
                  //   description: this.surveyResponse.data.keterangan,
                  //   subId: this.user.sub_id,
                  //   raw_response: this.surveyResponse.data,
                  //   survey_date: new Date(this.surveyResponse.data.tanggalSurvey).toISOString(),
                  // })
                }
              }

              this.openDialog = false
              this.$emit('fetch-response')
            } else if (this.title === 'edit') {
              try {
                await this.contactTagsMaker()
              } catch {}

              for (let i = 0; i < this.contacts.length; i++) {
                try {
                  await clientPocketBase.collection('crm_form_response_data').update(
                    this.responseId,
                    {
                      response: this.surveyResponse.data,
                    },
                    {
                      $autoCancel: false,
                    },
                  )
                } catch (err) {}

                if (this.formData.from_cms) {
                  try {
                    await this.$axios.post(this.formData.callback_urls.edit, {
                      formId: this.responseId,
                      answer: this.surveyResponse.data,
                    })
                  } catch (err) {
                    console.log(
                      `🥶🥶🥶🥶 ~ フ ク ロ ウ file: DialogAddResponse.vue:408 ~ フ ク ロ ウ saveResponse ~ フ ク ロ ウ err`,
                      err,
                    )
                  }
                }
              }

              this.openDialog = false
              this.responseId = ''
              this.selectedResponse = ''
              this.$emit('fetch-response')
            }
          } else {
            this.errorResponseValidationSnackbar = true
          }

          // await Promise.all(createResponses)
          // this.openDialog = false
          // this.$emit('fetch-response')
        }
      }
    },
    alertResults(sender) {
      const results = JSON.stringify(sender.data)
      alert(results)
    },
    async fetchContacts() {
      try {
        this.isLoading = true
        const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}contacts/contacts-survey`
        const { data: listContacts } = await axios.post(endpoint, {
          sub_id: this.user.sub_id,
        })
        this.contactsData = listContacts ? listContacts.data : []
      } catch (error) {
        console.log(error)
      }
      this.isLoading = false
    },
    remove(id) {
      const filtered = this.contacts.filter(el => el !== id)
      this.contacts = filtered
    },
    async fetchFilteredContacts() {
      try {
        let filter = ''
        this.contacts.forEach((contact, index) => {
          filter += `id = '${contact}'`
          if (index !== this.contacts.length - 1) {
            filter += ' || '
          }
        })

        return await clientPocketBase.collection('crm_contacts').getFullList(200, {
          filter,
        })
      } catch (error) {
        console.log(error)
      }
    },
    async fetchAllTags() {
      try {
      } catch (error) {}
    },
    async contactTagsMaker() {
      // update contact tag & creat contact tag for set tags
      for (let k = 0; k < this.contacts.length; k++) {
        const contact = this.contacts[k]
        let getRawTag = {}
        try {
          getRawTag = await clientPocketBase
            .collection('crm_contact_tags')
            .getFirstListItem(`contacts='${contact}' && sourceId = ''`, {
              expand: 'tag',
              $autoCancel: false,
            })
        } catch (err) {}
        const newTag = { ...(getRawTag.rawData || {}), ...this.tags.raw_data }
        const selected = Object.keys(newTag)

        // console.log(contact, selected, this.tags, '<<Viraa contact')
        // console.log('sayang contact', contact, selected, newTag);
        try {
          const updateContact = await clientPocketBase.collection('crm_contacts').update(
            contact,
            {
              tags: selected.filter(key => newTag[key] !== null),
            },
            { $autoCancel: false },
          )
        } catch (err) {
          console.log(err)
        }
        for (let i = 0; i < selected.length; i++) {
          const id = this.$Md5Hash(`${selected[i]}-${contact}`)
          if (newTag[selected[i]] === null) {
            try {
              const updateContactTag = await clientPocketBase
                .collection('crm_contact_tags')
                .delete(id, { $autoCancel: false })
            } catch (err) {}
          } else {
            try {
              const updateContactTag = await clientPocketBase
                .collection('crm_contact_tags')
                .delete(id, { $autoCancel: false })
            } catch (err) {}
            if (this.tags.raw_data[selected[i]] !== null) {
              try {
                const createContactTag = await clientPocketBase.collection('crm_contact_tags').create(
                  {
                    id,
                    tag: selected[i],
                    variant: newTag[selected[i]].value,
                    contacts: contact,
                    subId: this.user.sub_id,
                    rawData: Object.fromEntries(Object.entries(newTag).filter(([_, v]) => v != null)),

                    // msgTagData: thxis.action === 'msg' ? this.model : null,
                  },
                  { $autoCancel: false },
                )
              } catch (err) {
                console.log(err, '<<erorr create')
              }
            }
          }
        }
      }
    },
  },
}
</script>
<style scoped>
.select-container {
  position: relative;
  display: inline-block;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
</style>

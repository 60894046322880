class ApexChartFormater {
  static totalReply() {
    return {
      chart: {
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'left',
        fontSize: '14px',
        fontFamily: '"Inter", sans-serif',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: false,
        curve: 'straight',
      },
      colors: [
        // $themeColors.warning,
        // $themeColors.error,
        // $themeColors.secondary,
        // $themeColors.info,
        // $themeColors.success,
        '#F0D9FF',
        '#ab7efd',
        '#e0cffe',
        '#BFA2DB',
        '#b992fe',
      ],
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      fill: {
        opacity: 1,
        type: 'solid',
      },
      xaxis: {
        categories: [],
      },
    }
  }
  static dataFormater(chartUserData, chartStatistic, cartFormart, type) {
    let newArr = JSON.parse(JSON.stringify(chartUserData))
    const result = newArr.map(el => {
      for (const property in chartStatistic) {
        cartFormart.xaxis.categories.push(property)
        if (chartStatistic[property].hasOwnProperty(el.name)) {
          for (const userStatistic in chartStatistic[property]) {
            if (userStatistic === el.name) {
              if (type === 'count_reply') {
                el.data.push(chartStatistic[property][userStatistic].count_reply)
              } else if (type === 'avg_reply') {
                el.data.push(Math.round(chartStatistic[property][userStatistic].avg_reply))
              }
            }
          }
        }
      }
      return el
    })
    return result
  }
}
module.exports = ApexChartFormater

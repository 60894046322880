<template>
  <v-card>
    <!-- dialog config -->
    <v-dialog v-model="dialogLabel" width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ `${$t('add')} ${$t('Label')}` }}
          </h3>
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-divider />

        <v-card-text>
          <v-row>
            <div v-for="(label, index) of labelData" :key="index">
              <v-col cols="12" md="12">
                <div>
                  <v-checkbox v-model="selectedLabel[index]">
                    <template v-slot:label>
                      <v-icon dark left :style="{ color: label.color }">
                        {{ icons.mdiLabel }}
                      </v-icon>
                      {{ label.label }}
                    </template>
                  </v-checkbox>
                </div>
              </v-col>
            </div>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#ffeeee" @click="cancel">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="primary" class="" @click="openConfig">
            {{ `${$t('add')}` }}
          </v-btn>
          <v-btn color="success" @click="agree">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog label -->
    <dialogLabel ref="dialogLabel"></dialogLabel>
  </v-card>
</template>

<script>
import { uuid } from 'vue-uuid'
import { mdiLabel, mdiPlus } from '@mdi/js'
import pouchDbMixin from '../../mixins/pouchDbMixin'

import dialogLabel from './DialogLabel.vue'

export default {
  components: {
    dialogLabel,
  },
  mixins: [pouchDbMixin],
  data: () => ({
    dialogLabel: false,
    resolve: null,
    reject: null,

    dbSettings: null,
    dbRemoteSettings: null,
    columnsSetting: null,
    labelSetting: null,
    labelData: [],
    labelValue: [],
    tableColumns: [],

    isContact: true,
    contacts: [],
    chats: [],
    contactChat: null,

    selectedLabel: [],

    icons: {
      mdiPlus,
      mdiLabel,
    },
  }),
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  beforeDestroy() {
    this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_SETTINGS)
  },
  methods: {
    async open(contacts, labelData, labelValue) {
      this.dialogLabel = true
      this.contacts = contacts
      this.labelData = labelData
      this.labelValue = labelValue
      this.labelData.forEach(label => {
        this.selectedLabel.push(false)
      })

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    async agree() {
      this.contacts.forEach(curContact => {
        this.labelData.forEach((y, index) => {
          if (this.selectedLabel[index]) {
            const exist = this.labelValue.find(
              x =>
                x.id &&
                x.contact !== null &&
                x.contact.phone_number === curContact.phone_number &&
                x.labelName === y.label,
            )
            if (!exist) {
              const id = uuid.v1()
              this.labelValue.push({
                id,
                labelName: y.label,
                label: y,
                contact: curContact,
                chat: null,
              })
            }
          }
        })
      })

      // const res = await this.$store.dispatch('global/updateSettingsByKey', {
      //   sub_id: this.user.sub_id,
      //   token: this.user.token,
      //   key: 'label_value',
      //   value: this.labelValue,
      // })
      // if (res.status) {
      // }
      this.resolve({
        status: true,
        data: this.labelValue,
      })
      this.dialogLabel = false
    },
    cancel() {
      this.resolve(false)
      this.dialogLabel = false
    },
    addNewLabel() {},
    openConfig() {
      this.$refs.dialogLabel.open(this.labelData).then(async confirm => {
        // const labelsPg = await this.$store.dispatch('global/getSettingsByKey', {
        //   sub_id: this.user.sub_id,
        //   token: this.user.token,
        //   key: 'label',
        // })
        // if (labelsPg.status) {
        //   this.labelData = labelsPg.data.value
        // }
      })
    },
  },
}
</script>
<style scoped>
.bottom-action {
  display: none;
}
@media (max-width: 536px) {
  .hide-buton {
    display: none;
  }
  .bottom-action {
    display: inherit;
  }
  .block-condition {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
  }
}
</style>

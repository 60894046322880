<template>
  <div>
    <!-- dialog alert -->
    <v-dialog v-model="dialogAlert" width="500">
      <v-card>
        <v-card-title color="error">
          {{ $t('warning') }}
        </v-card-title>

        <v-card-text>
          {{ alertText }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialogAlert = false">
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog config -->
    <v-dialog v-model="dialogConfig" width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ $t('contacts.config') }}
          </h3>
          <v-spacer></v-spacer>
          <v-btn color="#ffeeee" class="mr-3" @click="dialogConfig = false">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" @click="agree">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
        <v-divider />

        <v-card-text>
          <v-row>
            <v-col cols="12" lg="12">
              <table class="billing-address-table">
                <tr>
                  <td style="width: 40px"></td>
                  <td style="width: 200px">
                    <p class="text-no-wrap text--primary font-weight-medium">
                      {{ $t('contacts.columnName') }}
                    </p>
                  </td>
                  <td style="width: 175px">
                    <p class="text-no-wrap text--primary font-weight-medium">
                      {{ $t('contacts.defaultValue') }}
                    </p>
                  </td>
                  <td style="width: 75px">
                    <p class="text-no-wrap text--primary font-weight-medium">
                      {{ $t('contacts.showField') }}
                    </p>
                  </td>
                  <td style="width: 15px"></td>
                  <td style="width: 75px">
                    <p class="text-no-wrap text--primary font-weight-medium">
                      {{ $t('contacts.required') }}
                    </p>
                  </td>
                  <td style="width: 15px"></td>
                  <td style="width: 75px">
                    <p class="text-no-wrap text--primary font-weight-medium">
                      {{ $t('contacts.action') }}
                    </p>
                  </td>
                </tr>
                <draggable
                  v-model="listDraggable"
                  handle=".handle"
                  tag="tbody"
                  @start="drag = true"
                  @end="drag = false"
                >
                  <tr v-for="(data, index) of listDraggable" :key="index">
                    <td style="width: 40px" class="handle cursor-pointer">
                      <v-icon large color="green darken-2">
                        {{ icons.mdiDragVertical }}
                      </v-icon>
                    </td>
                    <td style="width: 100px">
                      <v-text-field
                        v-model="data.text"
                        class="mr-4"
                        disa
                        single-line
                        hide-details
                        dense
                        outlined
                        solo
                        :disabled="!data.editable || data.value === 'name'"
                      ></v-text-field>
                    </td>
                    <td style="width: 200px">
                      <v-text-field
                        v-model="data.defaultValue"
                        class="mr-4"
                        disa
                        single-line
                        hide-details
                        dense
                        outlined
                        solo
                        :disabled="!data.editable || data.value === 'name'"
                      ></v-text-field>
                    </td>
                    <td class="center" style="width: 75px">
                      <v-checkbox v-model="data.show"></v-checkbox>
                    </td>
                    <td style="width: 15px"></td>
                    <td class="center" style="width: 75px">
                      <v-checkbox v-model="data.isRequired"></v-checkbox>
                    </td>
                    <td style="width: 15px"></td>
                    <td style="width: 75px">
                      <v-btn v-if="data.deleteable" icon @click.stop="deleteColumn(data, index)">
                        <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </draggable>
              </table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="mr-3" @click="addNewColumn">
            {{ $t('new') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { mdiDragVertical, mdiDeleteOutline } from '@mdi/js'
import pouchDbMixin from '../../mixins/pouchDbMixin'

export default {
  components: {
    draggable,
  },
  mixins: [pouchDbMixin],
  data: () => ({
    dialogAlert: false,
    alertText: '',
    dialogConfig: false,
    listDraggable: [],

    resolve: null,
    reject: null,
    icons: {
      mdiDragVertical,
      mdiDeleteOutline,
    },

    // DB SETTING
    dbSettings: null,
    dbRemoteSettings: null,
    columnsSetting: null,
    tableColumns: [],
    idlist: '',
  }),
  beforeDestroy() {
    this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_SETTINGS)
  },
  methods: {
    isEmptyOrSpaces(str) {
      return str === null || str.match(/^ *$/) !== null
    },
    async open(columnsEditable, idlist) {
      // this.connectToDb()

      await this.connectSettings(false)
      await this.loadSetting()

      this.$eventBus.$on(process.env.VUE_APP_EVENT_UPDATE_SETTINGS, payload => {
        this.loadSetting()
      })

      this.dialogAlert = false
      this.alertText = ''
      this.dialogConfig = true
      this.idlist = idlist
      const configTemp = []
      for (const column of columnsEditable) {
        configTemp.push(column)
      }

      this.listDraggable = configTemp.filter(x => x).sort((a, b) => (a.order_number < b.order_number ? -1 : 0))

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    async agree() {
      this.listDraggable.forEach((data, index) => {
        data.order_number = index
      })
      const configTemp = this.listDraggable
      const notValid = configTemp.find(x => this.isEmptyOrSpaces(x.text))
      if (notValid) {
        this.showAlert(this.$t('columnNameRequired'))
      } else {
        this.dialogConfig = false
        configTemp.forEach(x => {
          if (x.id === undefined) {
            x.id = this.$nanoid()
            if (x.deleteable) {
              x.name = x.text.toLowerCase().replaceAll(' ', '_')
              x.field = x.text.toLowerCase().replaceAll(' ', '_')
              x.value = x.text.toLowerCase().replaceAll(' ', '_')
              x.label = x.text
            }
          } else if (x.deleteable) {
            x.label = x.text
          }
        })
        const finalConfig = []
        this.tableColumns.forEach(col => {
          const column = configTemp.find(x => x.id === col.id)
          if (column !== undefined) {
            finalConfig.push(column)
          } else if (!col.deleteable) {
            finalConfig.push(col)
          }
        })
        configTemp.forEach(col => {
          const column = this.tableColumns.find(x => x.id === col.id)
          if (column === undefined) {
            finalConfig.push(col)
          }
        })
        await this.saveAttribute(finalConfig)
      }
    },
    cancel() {
      this.resolve({
        status: false,
        data: [],
      })
      this.dialogConfig = false
    },
    addNewColumn() {
      this.listDraggable.push({
        name: '',
        align: 'center',
        label: '',
        field: '',
        value: '',
        text: '',
        sortable: true,
        type: 'text',
        show: false,
        showable: true,
        formatDate: '',
        deleteable: true,
        isRequired: false,
        defaultValue: '',
        editable: true,
        order_number: this.tableColumns.length + 1,
      })
    },
    deleteColumn(item, index) {
      this.listDraggable.splice(index, 1)
    },
    async saveAttribute(data) {
      //console.log(data, ' == DATA')
      //console.log(this.columnsSetting, ' == COLUMNS SETTING')

      const self = this
      this.columnsSetting.value = data

      this.updateSettings(this.columnsSetting, async x => {
        self.resolve({
          status: true,
          data: [],
        })
        self.dialogConfig = false
      })
    },
    async loadSetting() {
      const items = await this.loadSettings()
      const colSetting = items.find(x => x._id === 'columns')
      const tempTableValue = colSetting
        ? colSetting.value.filter(
            x => x.value === 'phone_number' || x.value === 'phone_number_show' || x.value === 'name',
          )
        : []
      if (this.idlist) {
        const colSettingList = items.find(x => x._id === `${this.idlist}columns`)
        if (colSettingList) {
          this.columnsSetting = colSettingList
          this.tableColumns = colSettingList.value
        } else {
          // const self = this

          await this.updateSettings(
            {
              _id: `${this.idlist}columns`,
              key: `${this.idlist}columns`,
              value: tempTableValue,
            },
            async x => {
              this.columnsSetting = await this.loadSettingsById(x.id)
              this.tableColumns = this.columnsSetting.value
            },
          )
        }
      }
    },
    showAlert(warningText) {
      this.alertText = warningText
      this.dialogAlert = true
    },
  },
}
</script>

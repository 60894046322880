<template>
  <v-card class="mb-6">
    <v-snackbar
      v-model="showSnackbarCopyNotSupported"
      color="error"
      :timeout="2000"
      :top="true"
      style="z-index: 5 !important"
    >
      {{ $t('broadcasts.linkAlert') }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="closeShowSnackbarCopyNotSupported()">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-expand-transition>
      <v-tabs v-model="tab" show-arrows>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab key="opening">
          {{ $t('messageTemplates.openingChat') }}
        </v-tab>
        <!-- <v-tab key="onreply">
          {{ $t('messageTemplates.onReply') }}
        </v-tab> -->
      </v-tabs>
    </v-expand-transition>
    <v-expand-transition>
      <v-tabs-items v-model="tab">
        <v-tab-item key="opening">
          <v-card flat height="auto" class="mb-8">
            <v-container>
              <v-row>
                <v-col v-if="!chatMessage" cols="12" md="6">
                  <v-textarea
                    id="messageText"
                    v-model="formAddTemplate.message"
                    :rules="[v => !!v || 'You must agree to continue!']"
                    required
                    :label="$t('message')"
                    outlined
                    rows="7"
                    class="mb-3"
                    hide-details
                    @keyup="checkExtraFieldAv()"
                  ></v-textarea>

                  <v-card-actions>
                    <!-- <v-icon
                      class="vac-svg-button mr-2 ml-n4"
                      style="transform: matrix(-0.82, -0.57, -0.57, 0.82, 0, 0); !important;"
                      color="#5C9DDE"
                      @click="launchFilePicker()"
                      >{{ icons.mdiPaperclip }}</v-icon
                    >
                    <v-icon class="vac-svg-button" color="#5C9DDE" @click="openFileManagerMsg()">{{
                      icons.mdiBullhornOutline
                    }}</v-icon> -->

                    <input
                      ref="file"
                      type="file"
                      multiple
                      style="display: none"
                      @change="onFileChange($event.target.files)"
                    />

                    <div class="vac-svg-button" ref="triggerIcon" @click.stop="handleOpenAttachment">
                      <slot name="paperclip-icon">
                        <!-- <svg-icon name="paperclip" /> -->
                        <v-icon style="transform: matrix(-0.82, -0.57, -0.57, 0.82, 0, 0); !important;" color="#5C9DDE">
                          {{ icons.mdiPaperclip }}
                        </v-icon>
                      </slot>
                    </div>
                    <transition name="vac-slide-left" v-if="openAttachment">
                      <div
                        class="vac-menu-options"
                        style="top: 200px; position: absolute; left: 70px; max-width: fit-content"
                      >
                        <div class="vac-menu-list">
                          <div v-for="(item, index) in openAttachmentItems" :key="index">
                            <div class="vac-menu-item" @click="attachmentAction(item.action)">
                              {{ item.title }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </transition>
                  </v-card-actions>

                  <v-row class="mt-10">
                    <div v-if="isLoadingImage" class="loading-indicator">
                      <v-progress-circular :size="30" color="primary" indeterminate></v-progress-circular>
                    </div>
                    <v-col v-if="!isLoadingImage" v-for="(el, idx) in photoLinks" :key="idx">
                      <v-img :src="el.preview" width="120px" position="relative">
                        <v-icon position="absolute" top="0" left="0" color="#000000" @click="removeImage(idx)">
                          {{ icons.mdiClose }}
                        </v-icon></v-img
                      >
                      <span class="message-file-name">{{ el.filename }}</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-if="chatMessage" cols="12" md="6">
                  <v-textarea
                    id="messageText"
                    v-model="formAddTemplate.message"
                    :rules="[v => !!v || 'You must agree to continue!']"
                    required
                    :label="`${$t('message')} *`"
                    outlined
                    rows="7"
                    class="mb-3"
                    hide-details
                  ></v-textarea>
                  <!-- :disabled="showTemplate" -->
                  <!-- <uppyUploader
                    ref="uppy-data"
                    :key="'onchat-' + dataIndex"
                    :data-index="'onchat-' + dataIndex"
                    :status="status_message_template"
                    :data-uppy="propsUppyUploader"
                    :config="uppySettings"
                    :dashboard-config="uppyDashboardSettings"
                  /> -->
                </v-col>
                <v-col cols="12" md="6">
                  <div v-for="(button, index) of formAddTemplate.listButton" :key="index" class="mb-2">
                    <v-btn class="mt-1" color="primary">
                      {{ button.label }}
                    </v-btn>
                    <v-btn icon small @click="removeButton(index)">
                      <v-icon size="20">
                        {{ icons.mdiClose }}
                      </v-icon>
                    </v-btn>
                  </div>
                  <v-expansion-panels v-model="openedPanel" class="mb-2" accordion>
                    <v-expansion-panel>
                      <v-expansion-panel-header> Emoji 😃 </v-expansion-panel-header>
                      <v-expansion-panel-content class="align-center align-content-center px-0">
                        <emoji-picker
                          style="width: 100%"
                          :class="isDark ? 'dark' : 'light'"
                          @emoji-click="
                            e => {
                              addEmoji(e)
                            }
                          "
                        ></emoji-picker>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header> Ekstra Field </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col v-if="sourceContact !== 'Whatsapp'" cols="12" md="12">
                            <v-chip
                              small
                              block
                              color="primary"
                              class="text-capitalize mr-1"
                              @click="addText(`{{phone_number}}`)"
                            >
                              phone number
                            </v-chip>
                            <v-chip small block color="error" class="text-capitalize mr-1" @click="addText(`{{name}}`)">
                              Name
                            </v-chip>
                          </v-col>
                          <v-col v-if="sourceContact === 'Whatsapp'" cols="12" md="12">
                            <v-chip
                              small
                              block
                              color="primary"
                              class="text-capitalize mr-1"
                              @click="addText(`{{phone_number}}`)"
                            >
                              phone number
                            </v-chip>
                            <v-chip small block color="error" class="text-capitalize mr-1" @click="addText(`{{name}}`)">
                              Name
                            </v-chip>
                            <v-chip
                              v-for="item in extraFieldWA"
                              :key="item.value"
                              block
                              small
                              color="primary"
                              class="text-capitalize mr-1"
                              @click="addText(`{{name}}`)"
                            >
                              {{ item.name }}
                            </v-chip>
                          </v-col>
                          <v-col v-if="sourceContact !== 'Whatsapp'" cols="12">
                            <v-select
                              v-if="contactList.length > 0"
                              v-model="extraFieldChoosed"
                              :items="contactList"
                              label="Extra Field"
                              dense
                              hide-details
                              @change="changeExtraField()"
                            ></v-select>
                          </v-col>
                          <v-col v-if="sourceContact !== 'Whatsapp'" cols="12">
                            <v-chip
                              v-for="item in extraField"
                              :key="item.value"
                              block
                              small
                              dense
                              color="primary"
                              class="text-capitalize mr-1"
                              @click="addText(`{{name}}`)"
                            >
                              {{ item.name }}
                            </v-chip>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <!-- <v-expansion-panel >
                      <v-expansion-panel-header> Link Button </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-text-field
                          v-model="labelLink"
                          required
                          label="Label"
                          outlined
                          dense
                          hide-details
                          class="mb-3"
                        ></v-text-field>
                        <v-text-field
                          v-model="valueLink"
                          required
                          label="Link URL"
                          outlined
                          dense
                          hide-details
                          class="mb-3"
                        ></v-text-field>
                        <v-btn color="primary" @click="addButtonLink">
                          {{ $t('add') }}
                        </v-btn>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header> Call Button </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-text-field
                          v-model="labelCall"
                          required
                          label="Label"
                          outlined
                          dense
                          hide-details
                          class="mb-3"
                        ></v-text-field>
                        <v-text-field
                          v-model="valueCall"
                          required
                          label="Phone Number"
                          outlined
                          type="number"
                          min="0"
                          dense
                          hide-details
                          class="mb-3"
                        ></v-text-field>
                        <v-btn color="primary" @click="addButtonCall">
                          {{ $t('add') }}
                        </v-btn>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header> Reply Button </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-text-field
                          v-model="labelReply"
                          required
                          label="Reply text"
                          outlined
                          dense
                          hide-details
                          class="mb-3"
                        ></v-text-field>
                        <v-btn color="primary" @click="addButtonReply">
                          {{ $t('add') }}
                        </v-btn>
                      </v-expansion-panel-content>
                    </v-expansion-panel> -->
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="extraFieldAlert" cols="12" md="12">
                  <v-expand-transition>
                    <v-alert color="secondary" border="left" colored-border dense text class="caption">
                      <strong>{{ $t('broadcasts.notAllField') }}</strong> {{ $t('broadcasts.inOpeningChat') }}
                      <strong>{{ $t('broadcasts.contactListSelected') }}</strong>
                    </v-alert>
                  </v-expand-transition>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-tab-item>
        <!-- <v-tab-item key="onreply">
          <v-card flat>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-textarea
                    v-model="formAddTemplate.on_reply_message.message"
                    :rules="[v => !!v || 'You must agree to continue!']"
                    required
                    label="Pesan"
                    outlined
                    rows="7"
                    class="mb-3"
                    hide-details
                    @keyup="checkExtraFieldAvOnreply()"
                  ></v-textarea>
                  <v-row>
                    <v-col
                      v-if="sourceContact !== 'Whatsapp'"
                      cols="12"
                      md="12"
                    >
                      <v-chip
                        small
                        block
                        color="primary"
                        class="text-capitalize mr-1"
                        @click="formAddTemplate.on_reply_message.message += form.number;checkExtraFieldAvOnreply()"
                      >
                        phone number
                      </v-chip>
                      <v-chip
                        small
                        block
                        color="error"
                        class="text-capitalize mr-1"
                        @click="formAddTemplate.on_reply_message.message += form.name;checkExtraFieldAvOnreply()"
                      >
                        Name
                      </v-chip>
                    </v-col>
                    <v-col
                      v-if="sourceContact === 'Whatsapp'"
                      cols="12"
                      md="12"
                    >
                      <v-chip
                        small
                        block
                        color="primary"
                        class="text-capitalize mr-1"
                        @click="formAddTemplate.on_reply_message.message += form.number;checkExtraFieldAvOnreply()"
                      >
                        phone number
                      </v-chip>
                      <v-chip
                        small
                        block
                        color="error"
                        class="text-capitalize mr-1"
                        @click="formAddTemplate.on_reply_message.message += form.name;checkExtraFieldAvOnreply()"
                      >
                        Name
                      </v-chip>
                      <v-chip
                        v-for="item in extraFieldWA"
                        :key="item.value"
                        block
                        small
                        color="primary"
                        class="text-capitalize mr-1"
                        @click="formAddTemplate.on_reply_message.message += `{{${item.field}}}`;checkExtraFieldAvOnreply()"
                      >
                        {{ item.name }}
                      </v-chip>
                    </v-col>
                    <v-col
                      v-if="sourceContact !== 'Whatsapp'"
                      cols="12"
                    >
                      <v-select
                        v-if="contactList.length > 0"
                        v-model="extraFieldChoosed"
                        :items="contactList"
                        label="Extra Field"
                        dense
                        hide-details
                        @change="changeExtraField()"
                      ></v-select>
                    </v-col>
                    <v-col
                      v-if="sourceContact !== 'Whatsapp'"
                      cols="12"
                    >
                      <v-chip
                        v-for="item in extraField"
                        :key="item.value"
                        block
                        small
                        dense
                        color="primary"
                        class="text-capitalize mr-1"
                        @click="formAddTemplate.on_reply_message.message += `{{${item.field}}}`;checkExtraFieldAvOnreply()"
                      >
                        {{ item.name }}
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <uppyUploader
                    ref="uppy-data-onreply"
                    :key="'onreply-'+dataIndexOnreply"
                    :data-index="'onreply-'+dataIndexOnreply"
                    :status="status_message_template"
                    :data-uppy="propsUppyUploaderReply"
                    :config="uppySettings"
                    :dashboard-config="uppyDashboardSettings"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-if="extraFieldAlertReply"
                  cols="12"
                  md="12"
                >
                  <v-expand-transition>
                    <v-alert
                      color="secondary"
                      border="left"
                      colored-border
                      dense
                      text
                      class="caption"
                    >
                      <strong>{{ $t('broadcasts.notAllField') }}</strong> {{ $t('broadcasts.onReplyChat') }} <strong>{{ $t('broadcasts.contactListSelected') }}</strong>
                    </v-alert>
                  </v-expand-transition>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-tab-item> -->
      </v-tabs-items>
    </v-expand-transition>
  </v-card>
</template>
<script>
import uppyUploader from '@/components/UppyUploader.vue'
import { fileManagerModal } from '@/helpers/mini-file-manager'
import fileManagerOptions from '@/views/MyAssets/options/optionsFileManager.js'
import '@/views/MyAssets/style.css'
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiBullhornOutline, mdiClose, mdiPaperclip } from '@mdi/js'
import 'emoji-picker-element'
import insertTextAtCursor from 'insert-text-at-cursor'
import moment from 'moment'

export default {
  components: {
    uppyUploader,
  },
  props: {
    selectedListContacts: { type: Array, default: () => [] },
    sourceContact: { type: String, default: 'Whatsapp' },
    dataExtraFieldProps: { type: Object, default: () => {} },
    activeStep: { type: Number, default: 2 },
    // message: { type: String, default: '' },
    // image: { type: Array, default: () => [] },
  },
  emits: ['update-message'],
  setup() {
    const { isDark } = useAppConfig()

    return {
      isDark,
    }
  },
  data() {
    return {
      openAttachment: false,
      openAttachmentItems: [
        { title: 'My Asset', action: 'myasset' },
        { title: 'Upload Files', action: 'filepicker' },
      ],
      icons: {
        mdiClose,
        mdiBullhornOutline,
        mdiPaperclip,
      },
      tab: null,
      linkWaRediurect: '',
      labelLink: '',
      valueLink: '',
      labelCall: '',
      valueCall: '',
      labelReply: '',
      valueReply: '',
      formAddTemplate: {
        template_name: 'Custom Template',
        category: {},
        language: '',
        message: '',
        quick_reply: false,
        link_attachment: '',
        last_update: '',
        attachments: [],
        id: '',
        on_reply: false,
        on_reply_message: {
          message: '',
          attachments: [],
        },
        listButton: [],
        buttonHeader: '',
        buttonFooter: '',
      },
      status_message_template: 'add',
      dataIndex: '0',
      dataIndexOnreply: '0',
      propsUppyUploader: null,
      propsUppyUploaderReply: null,
      uppySettings: {
        restrictions: {
          maxFileSize: 100 * 1024 * 1024,
          allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif'],
        },
      },
      uppyDashboardSettings: {
        note: ' Image Only',
        theme: 'dark',
      },
      form: {
        name: '{{name}}',
        number: '{{phone_number}}',
      },
      extraFieldList: [],
      extraFieldChoosed: '',
      extraField: [],
      extraFieldWA: [],
      extraFieldAlert: false,
      extraFieldAlertReply: false,
      localExtraFieldContainer: [],
      showSnackbarCopyNotSupported: false,
      openedPanel: null,
      photoLinks: [],
      showFiles: false,
      isLoadingImage: false,
    }
  },
  computed: {
    chatMessage() {
      return this.$store.getters['broadcast/getDefaultCustomMessage']
    },
    getImage() {
      return this.$refs['uppy-data'].uppy.getFiles()
    },
    getImageReply() {
      if (this.$refs['uppy-data-onreply'] !== undefined) {
        //console.log('ada uppy reply files')

        return this.$refs['uppy-data-onreply'].uppy.getFiles()
      }
      //console.log('no uppy reply files')

      return []
    },
    contactList() {
      let data = []

      if (this.selectedListContacts.length > 0) {
        data = [{ text: 'Global', value: 'global' }, ...this.selectedListContacts]
      }

      return data
    },
    dataTracker() {
      return this.$store.getters['link/getTrackerListLink']
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
  },

  watch: {
    contactList() {
      const extra = this.$store.getters['broadcast/getBroadcastExtraField'].global
      this.extraFieldChoosed = 'global'
      this.extraField = extra
      this.checkExtraFieldAv()
      this.checkExtraFieldAvOnreply()
    },
    activeStep() {
      if (this.activeStep === 3) {
        console.log('ke emit nyund', this.formAddTemplate)
        this.$emit('update-message', this.formAddTemplate)
      }
    },
  },
  async mounted() {
    // //console.log(this.$store.getters['link/getSendLinkBroadcast'], 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
    // //console.log(this.$store.getters['broadcast/getDefaultCustomMessage'])
    if (this.chatMessage) {
      this.formAddTemplate.message += this.chatMessage.content
      this.propsUppyUploader = {
        attachments: JSON.parse(JSON.stringify(this.chatMessage.files)).map(file => {
          file = {
            uploadURL: file.url,
            filename: file.name,
            type: !file.audio ? `image/${file.type}` : '',
            size: file.size,
            extension: file.type,
          }

          return file
        }),
      }

      // this.on_reply_message.attachments = this.$refs['uppy-data-onreply']?.uppy?.getFiles()
    }
    this.formAddTemplate.message += this.$store.getters['link/getSendLinkBroadcast']

    // this.addText(this.$store.getters['link/getSendLinkBroadcast'])
    // this.linkWaRediurect = process.env.VUE_APP_URL_WA_LINK
    const extraField = this.$store.getters['broadcast/getBroadcastExtraField']
    if (this.sourceContact === 'Whatsapp') {
      this.extraFieldWA = extraField.Whatsapp
    } else {
      this.extraField = extraField.global
      this.extraFieldChoosed = { text: 'Global', value: 'global' }
      this.populateLocalField()
      this.checkExtraFieldAv()
      this.checkExtraFieldAvOnreply()
    }
    this.uppyDashboardSettings = {
      note: this.$t('broadcasts.uploadNotes'),
      theme: this.isDark ? 'dark' : 'light',
    }
  },
  methods: {
    attachmentAction(action) {
      switch (action) {
        case 'myasset':
          this.openFileManagerMsg()
          this.openAttachment = false
          break
        case 'filepicker':
          this.launchFilePicker()
          this.openAttachment = false
          break
        default:
          break
      }
    },
    handleOpenAttachment() {
      this.openAttachment = !this.openAttachment
    },
    launchFilePicker() {
      this.$refs.file.value = ''
      this.$refs.file.click()
    },
    async onFileChange(files) {
      this.fileDialog = true
      this.isLoadingImage = true
      // this.focusTextarea()
      // console.log('sayang', files);

      let formData = new FormData()
      formData.append('sub_id', this.user.sub_id)

      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const fileURL = URL.createObjectURL(file)
        const blobFile = await fetch(fileURL).then(res => res.blob())
        const typeIndex = file.name.lastIndexOf('.')
        formData.append('files', blobFile, file.name)
      }
      try {
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }

        let { data: imageData } = await this.$axios.post(`https://file-upload.prod.marketa.id/upload`, formData, config)
        // console.log(imageData, 'haloo<')

        if (imageData.data.failed.length) {
          imageData.data.failed.forEach(el => {
            if (el.mimeType === 'text/csv') {
              el.preview = 'https://cdn-icons-png.flaticon.com/512/8242/8242984.png'
            } else if (el.mimeType.includes('spreadsheetml')) {
              el.preview = '/file-manager/icons/application-vnd.ms-excel.svg'
            } else if (el.mimeType === 'application/pdf' || el.uploadRelativePath.includes('pdf')) {
              el.preview = '/file-manager/icons/application-pdf.svg'
            } else if (el.mimeType.includes('video')) {
              el.preview = 'https://fm.prod.marketa.id/unsafe/200x0/'+el.uploadURL.replace(/^https?:\/\//, '').replace(/^fm.prod.marketa.id\/uploads/, 'data')
              // el.preview = `https://upload.wikimedia.org/wikipedia/commons/thumb/1/1d/Gnome-video-x-generic.svg/2048px-Gnome-video-x-generic.svg.png`
            } else if (el.mimeType === 'text/plain') {
              el.preview = 'https://cdn-icons-png.flaticon.com/512/3979/3979306.png'
            } else if (el.mimeType.includes('document')) {
              el.preview = '/file-manager/icons/application-msword-template.svg'
            } else if (el.mimeType.includes('image')) {
              // el.preview = el.uploadURL
              el.preview = 'https://fm.prod.marketa.id/unsafe/200x0/'+el.uploadURL.replace(/^https?:\/\//, '').replace(/^fm.prod.marketa.id\/uploads/, 'data')
            } else {
              el.preview = '/file-manager/icons/text.svg'
            }
          })
          this.photoLinks = [...this.photoLinks, ...imageData.data.failed]
          this.formAddTemplate.attachments = [...this.formAddTemplate.attachments, ...imageData.data.failed]
        } else {
          imageData.data.uploaded.forEach(el => {
            if (el.mimeType === 'text/csv') {
              el.preview = 'https://cdn-icons-png.flaticon.com/512/8242/8242984.png'
            } else if (el.mimeType.includes('spreadsheetml')) {
              el.preview = '/file-manager/icons/application-vnd.ms-excel.svg'
            } else if (el.mimeType === 'application/pdf' || el.uploadRelativePath.includes('pdf')) {
              el.preview = '/file-manager/icons/application-pdf.svg'
            } else if (el.mimeType.includes('video')) {
              el.preview = 'https://fm.prod.marketa.id/unsafe/200x0/'+el.uploadURL.replace(/^https?:\/\//, '').replace(/^fm.prod.marketa.id\/uploads/, 'data')
              // el.preview = `https://upload.wikimedia.org/wikipedia/commons/thumb/1/1d/Gnome-video-x-generic.svg/2048px-Gnome-video-x-generic.svg.png`
            } else if (el.mimeType === 'text/plain') {
              el.preview = 'https://cdn-icons-png.flaticon.com/512/3979/3979306.png'
            } else if (el.mimeType.includes('document')) {
              el.preview = '/file-manager/icons/application-msword-template.svg'
            } else if (el.mimeType.includes('image')) {
              // el.preview = el.uploadURL
              el.preview = 'https://fm.prod.marketa.id/unsafe/200x0/'+el.uploadURL.replace(/^https?:\/\//, '').replace(/^fm.prod.marketa.id\/uploads/, 'data')
            } else {
              el.preview = '/file-manager/icons/text.svg'
            }
          })
          this.photoLinks = [...this.photoLinks, ...imageData.data.uploaded]
          this.formAddTemplate.attachments = [...this.formAddTemplate.attachments, ...imageData.data.uploaded]
        }
        this.isLoadingImage = false
      } catch (error) {
        console.log(error)
      }
      setTimeout(() => (this.fileDialog = false), 500)
    },
    closeShowSnackbarCopyNotSupported() {
      this.showSnackbarCopyNotSupported = false
    },
    removeImage(idx) {
      this.formAddTemplate.attachments = this.formAddTemplate.attachments.filter((element, index) => index !== idx)
      this.photoLinks = this.photoLinks.filter((element, index) => index !== idx)
    },
    addButtonLink() {
      if (this.labelLink && this.valueLink) {
        this.formAddTemplate.listButton.push({
          label: this.labelLink,
          urlButton: {
            displayText: this.labelLink,
            url: `https://${this.valueLink}`,
          },
        })
        this.labelLink = ''
        this.valueLink = ''
        this.openedPanel = null
      }
      this.resetIndex()

      // if (this.valueLink.includes('http')) {
      // } else {
      //   this.showSnackbarCopyNotSupported = true
      // }
    },
    openFileManagerMsg() {
      fileManagerModal(fileManagerOptions(this.user.sub_id), this.saveFile)
    },
    saveFile(data) {
      this.isLoadingImage = true

      // this.formAddTemplate.attachments.push(...data)
      // const images = Promise.all(
      //   data.map(async el => {
      //     const res = 'https://fm.prod.marketa.id/uploads/' + el.uploadRelativePath
      //     this.photoLinks.push(res)
      //   }),
      // )
      // text / plain
      data.forEach(el => {
        const res = 'https://fm.prod.marketa.id/uploads/' + el.uploadRelativePath
        el.uploadURL = res
        if (el.mimeType === 'text/csv') {
          el.preview = 'https://cdn-icons-png.flaticon.com/512/8242/8242984.png'
        } else if (el.mimeType.includes('spreadsheetml') || el.uploadRelativePath.includes('xlsx')) {
          el.preview = '/file-manager/icons/application-vnd.ms-excel.svg'
        } else if (el.mimeType === 'application/pdf') {
          el.preview = '/file-manager/icons/application-pdf.svg'
        } else if (el.mimeType.includes('video')) {
          el.preview = 'https://fm.prod.marketa.id/unsafe/200x0/'+el.uploadURL.replace(/^https?:\/\//, '').replace(/^fm.prod.marketa.id\/uploads/, 'data')
          // el.preview = `https://upload.wikimedia.org/wikipedia/commons/thumb/1/1d/Gnome-video-x-generic.svg/2048px-Gnome-video-x-generic.svg.png`
        } else if (el.mimeType === 'text/plain') {
          el.preview = 'https://cdn-icons-png.flaticon.com/512/3979/3979306.png'
        } else if (el.mimeType.includes('document')) {
          el.preview = '/file-manager/icons/application-msword-template.svg'
        } else if (el.mimeType.includes('image')) {
            // el.preview = el.uploadURL
            el.preview = 'https://fm.prod.marketa.id/unsafe/200x0/'+el.uploadURL.replace(/^https?:\/\//, '').replace(/^fm.prod.marketa.id\/uploads/, 'data')
        } else {
          el.preview = '/file-manager/icons/text.svg'
        }
      })
      this.photoLinks = [...this.photoLinks, ...data]
      this.formAddTemplate.attachments = [...this.photoLinks]

      this.isLoadingImage = false
    },
    addButtonCall() {
      if (this.labelCall && this.valueCall) {
        this.formAddTemplate.listButton.push({
          label: this.labelCall,
          callButton: {
            displayText: this.labelCall,
            phoneNumber: this.valueCall,
          },
        })
        this.labelCall = ''
        this.valueCall = ''
        this.openedPanel = null
      }
      this.resetIndex()
    },
    addButtonReply() {
      if (this.labelReply) {
        this.formAddTemplate.listButton.push({
          label: this.labelReply,
          quickReplyButton: {
            displayText: this.labelReply,
            id: 'id-like-buttons-message2',
          },
        })
        this.labelReply = ''
        this.valueReply = ''
        this.openedPanel = null
      }
      this.resetIndex()
    },
    removeButton(index) {
      this.formAddTemplate.listButton.splice(index, 1)
      this.resetIndex()
    },
    resetIndex() {
      this.formAddTemplate.listButton.forEach((btn, index) => {
        this.formAddTemplate.listButton[index].index = index + 1
      })
    },
    addEmoji(event) {
      insertTextAtCursor(document.querySelector('#messageText'), event.detail.unicode)
    },
    addText(text) {
      insertTextAtCursor(document.querySelector('#messageText'), text)
    },

    populateLocalField() {
      const extraField = this.$store.getters['broadcast/getBroadcastExtraField']
      const globalField = extraField.global.map(el => el.field)

      const tempLocalField = []
      const keys = Object.keys(extraField)
      // eslint-disable-next-line no-plusplus
      for (let index = 2; index < keys.length; index++) {
        const el = keys[index]
        extraField[el].forEach(elm => {
          if (!globalField.includes(elm.field)) tempLocalField.push(`{{${elm.field}}}`)
        })
      }
      this.localExtraFieldContainer = tempLocalField
    },
    changeExtraField() {
      //console.log('changeExtraField', this.extraFieldChoosed)
      const extraField = this.dataExtraFieldProps
      //console.log('changeExtraField', extraField)
      this.extraField = extraField[this.extraFieldChoosed]
    },
    checkExtraFieldAv() {
      if (this.selectedListContacts.length > 1) {
        const regexp = /{{\s*[\w\.]+\s*}}/g
        const match = this.formAddTemplate.message.match(regexp)
        let countLocalExtraField = 0
        if (match) {
          match.forEach(el => {
            if (this.localExtraFieldContainer.includes(el)) countLocalExtraField += 1
          })
        }
        this.extraFieldAlert = countLocalExtraField !== 0
      } else {
        this.extraFieldAlert = false
      }
    },
    checkExtraFieldAvOnreply() {
      if (this.selectedListContacts.length > 1) {
        const regexp = /{{\s*[\w\.]+\s*}}/g
        const match = this.formAddTemplate.on_reply_message.message.match(regexp)
        let countLocalExtraField = 0
        if (match) {
          match.forEach(el => {
            if (this.localExtraFieldContainer.includes(el)) countLocalExtraField += 1
          })
        }
        this.extraFieldAlertReply = countLocalExtraField !== 0
      } else {
        this.extraFieldAlertReply = false
      }
    },

    // set data for edit message template
    setMessageTemplate(dataEdit, index) {
      this.formAddTemplate.id = dataEdit.id
      this.formAddTemplate.message = dataEdit.message
      this.formAddTemplate.template_name = dataEdit.template_name
      this.formAddTemplate.last_update = moment().format('DD-MM-YYYYTHH:mm:ss')
      this.propsUppyUploader = dataEdit
      this.status_message_template = 'edit'
      this.dataIndex = `${index}`
      if (dataEdit.on_reply) {
        this.formAddTemplate.on_reply_message.message = dataEdit.on_reply_message.message
        this.formAddTemplate.on_reply_message.attachments = dataEdit.on_reply_message.attachments
        this.propsUppyUploaderReply = dataEdit.on_reply_message
        this.dataIndexOnreply = `${index}`
      }
      this.formAddTemplate.buttonHeader = dataEdit.buttonHeader
      this.formAddTemplate.buttonFooter = dataEdit.buttonFooter
    },
  },
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
.message-file-name {
  font-size: 12px;
  color: #000;
  width: 120px;
  white-space: normal; /* allow text to wrap */
  word-wrap: break-word; /* break long words if necessary */
  display: flex;
  justify-content: center;
  margin-top: 2px;
  text-align: center;
}
</style>
